


































































import Vue from 'vue'
import path from 'path'
import imgur from '../../../io/apis/imgur'
import MechImageSelector from './components/_MechImageSelector.vue'
import PilotImageSelector from './components/_PilotImageSelector.vue'

export default Vue.extend({
  name: 'web-image-selector',
  components: { MechImageSelector, PilotImageSelector },
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    selectedImage: null,
    imageData: null,
    loading: false,
  }),
  computed: {
    displayImage() {
      if (this.selectedImage) return this.selectedImage
      if (this.imageData) return `data:image/png;base64,${this.imageData}`
      else if (this.item.Portrait) return this.item.Portrait
      else return 'https://via.placeholder.com/550'
    },
    isPixel() {
      return this.selectedImage && path.basename(this.selectedImage).includes('_pixel')
    },
  },
  methods: {
    onChange(file: File | null) {
      if (!file) {
        this.imageData = null
        return
      }
      this.selectedImage = null
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          // get base64 without url headers for imgur
          this.imageData = btoa(reader.result as string)
        },
        false
      )
      reader.readAsBinaryString(file)
    },
    async saveImage() {
      if (this.selectedImage && this.validURL(this.selectedImage)) {
        this.item.PortraitController.SetCloudImage(this.selectedImage)
        this.close()
      } else if (this.selectedImage) {
        this.item.PortraitController.SetCloudImage(null)
        this.item.PortraitController.SetLocalImage(path.basename(this.selectedImage))
        this.close()
      } else {
        this.loading = true
        this.selectedImage = null
        const link = await imgur.uploadImage(this.imageData)
        try {
          this.item.PortraitController.SetCloudImage(link)
          this.$emit('notify', 'Загрузка в облако успешна')
        } catch (err) {
          this.$emit('notify', `Ошибка при загрузке в облако:<br>${err.message}`)
          this.loading = true
          this.selectedImage = null
        }
        this.close()
        this.$refs.fileInput.value = null
        this.loading = false
        this.imageData = null
      }
    },
    // Pulled from Stackoverflow: https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
    validURL(str: string): boolean {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ) // fragment locator
      return !!pattern.test(str)
    },
    open() {
      this.$refs.dialog.show()
    },
    close() {
      this.$refs.dialog.hide()
    },
  },
})

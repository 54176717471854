











































































































import Vue from 'vue'

export default Vue.extend({
  name: 'cc-reserve-item',
  props: {
    reserve: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    remove() {
      this.$emit('remove')
      this.dialog = false
    },
  },
})




































import Vue from 'vue'
import { Pilot } from '@/class'
import PilotRegistrationCard from '../../PilotSheet/components/PilotRegistrationCard.vue'

export default Vue.extend({
  name: 'confirm-page',
  components: { PilotRegistrationCard },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
    original: {
      type: Object,
      required: true,
    },
  },
  computed: {
    pilotReady(): boolean {
      return (
        this.pilot.HasIdent &&
        this.pilot.SkillsController.HasFullSkills &&
        this.pilot.TalentsController.HasFullTalents &&
        this.pilot.MechSkillsController.HasFullHASE
      )
    },
  },
  methods: {
    savePilot() {
      this.original.ApplyLevel(Pilot.Serialize(this.pilot))
      this.$router.push({ name: 'pilot_sheet', params: { id: this.pilot.ID } })
    },
    canContinue() {
      return (
        this.pilot.Callsign &&
        this.pilot.Name &&
        this.pilot.SkillsController.HasFullSkills &&
        this.pilot.TalentsController.HasFullTalents &&
        this.pilot.MechSkillsController.HasFullHASE &&
        this.pilot.LicenseController.HasLicenses &&
        this.pilot.CoreBonusController.HasCBs
      )
    },
  },
})




























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ name: 'cc-short-string-editor' })
export default class CCShortStringEditor extends Vue {
  @Prop({ type: String, required: false })
  readonly placeholder?: string
  @Prop({ type: Boolean, required: false })
  readonly inline?: boolean
  @Prop({ type: Boolean })
  readonly large?: boolean
  @Prop({ type: Boolean })
  readonly before?: boolean

  newStr = ''
  editing = false

  edit(): void {
    this.editing = true
    this.newStr = this.$slots.default[0].text ? this.$slots.default[0].text.trim() : ''
  }
  submit(): void {
    if (this.newStr.length) this.$emit('set', this.newStr)
    this.newStr = ''
    this.editing = false
  }
}
































































































































































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { MissionStore } from '@/store'
import { ActiveMission } from '@/class'

export default Vue.extend({
  name: 'active-mission-landing',
  data: () => ({
    headers: [
      { text: 'Название', value: 'Mission.Name', align: 'left' },
      { text: 'Сцена', value: 'Encounter' },
      { text: 'Раунд', value: 'Round' },
      { text: 'Дата начала', value: 'StartDate' },
      { text: '', value: 'Continue', align: 'right', sortable: false },
    ],
    availableHeaders: [
      { text: 'Название', value: 'Name', align: 'left' },
      { text: 'Сцены', value: 'Encounters' },
      { text: 'Ярлыки', value: 'Labels' },
      { text: '', value: 'Start', align: 'right', sortable: false },
    ],
    completedHeaders: [
      { text: 'Название', value: 'Mission.Name', align: 'left' },
      { text: 'Дата начала', value: 'StartDate' },
      { text: 'Дата конца', value: 'EndDate' },
      { text: 'Результат', value: 'Result' },
      { text: '', value: 'Remove', align: 'right', sortable: false },
    ],
  }),
  computed: {
    availableMissions() {
      const store = getModule(MissionStore, this.$store)
      return store.Missions
    },
    activeMissions() {
      const store = getModule(MissionStore, this.$store)
      return store.ActiveMissions.filter(x => !x.IsComplete)
    },
    completedMissions() {
      const store = getModule(MissionStore, this.$store)
      return store.ActiveMissions.filter(x => x.IsComplete)
    },
  },
  created() {
    const store = getModule(MissionStore, this.$store)
    store.Missions.forEach(m => m.ValidateSteps())
  },
  methods: {
    deleteActiveMission(m: ActiveMission) {
      m.SaveController.delete()
    },
  },
})

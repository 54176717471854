


























import { Vue, Component, Prop } from 'vue-property-decorator'
import EditButton from './subcomponents/_EditButton.vue'

@Component({
  name: 'cc-string-edit',
  components: { EditButton },
})
export default class CCStringEdit extends Vue {
  @Prop({ type: String, required: false, default: '' })
  readonly label: string

  @Prop({ type: Boolean, required: false })
  readonly number: boolean

  @Prop({ type: String, required: false, default: '' })
  readonly placeholder: string

  newString = ''

  dialog = false

  confirm(): void {
    this.save()
    this.hide()
  }
  reset(): void {
    this.$emit('reset')
    this.hide()
  }
  show(): void {
    this.dialog = true
  }
  hide(): void {
    this.dialog = false
  }

  save(): void {
    if (this.number && this.newString) this.$emit('save', parseInt(this.newString))
    else if (this.newString) this.$emit('save', this.newString)
    this.newString = ''
  }
}



































import Vue from 'vue'
export default Vue.extend({
  name: 'reserve-item',
  props: {
    reserve: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    icon: {
      type: String,
      required: false,
      default: 'cci-barrage',
    },
  },
})















































































import Vue from 'vue'

export default Vue.extend({
  name: 'frame-core-system-panel',
  props: {
    cs: {
      type: Object,
      required: true,
    },
  },
})

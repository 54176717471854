



















import Vue from 'vue'
import EquipmentCardBase from './_EquipmentCardBase.vue'

export default Vue.extend({
  name: 'cc-mech-weapon-card',
  components: { EquipmentCardBase },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    armor() {
      const attr = this.item.Bonuses.find(b => b.ID === 'pilot_armor')
      return attr ? attr.Value : 0
    },
    hp() {
      const attr = this.item.Bonuses.find(b => b.ID === 'pilot_hp')
      return attr ? attr.Value : 0
    },
    edef() {
      const attr = this.item.Bonuses.find(b => b.ID === 'pilot_edef')
      return attr ? attr.Value : 0
    },
    evasion() {
      const attr = this.item.Bonuses.find(b => b.ID === 'pilot_evasion')
      return attr ? attr.Value : 0
    },
    speed() {
      const attr = this.item.Bonuses.find(b => b.ID === 'pilot_speed')
      return attr ? attr.Value : 0
    },
  },
})

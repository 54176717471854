








































































































import Vue from 'vue'
import NpcPanel from './NpcPanel.vue'
import RosterGroup from '../../npc/components/RosterGroup.vue'
import { getModule } from 'vuex-module-decorators'
import { NpcStore } from '@/store'

export default Vue.extend({
  name: 'npc-selector',
  components: { NpcPanel, RosterGroup },
  data: () => ({
    npcs: [],
    side: 'Противник',
    grouping: null,
    headers: [{ text: 'Название', value: 'Name', align: 'left' }],
    search: '',
  }),
  created() {
    const compendium = getModule(NpcStore, this.$store)
    this.npcs = compendium.Npcs
  },
  methods: {
    generateNpcElementId: function(npc) {
      return `e_${this._uid}_${npc.ID}`
    },
    colorForSide(side: string) {
      switch (side) {
        case 'Противник':
          return 'enemy'
        case 'Союзник':
          return 'ally'
        case 'Нейтральный':
          return 'neutral'
      }
    },
  },
})

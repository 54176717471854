





























import Vue from 'vue'

export default Vue.extend({
  name: 'cc-pilot-license-item',
  props: {
    pilotLicense: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    color: 'primary',
  }),
})





















import Vue from 'vue'
import OptionsDialog from './OptionsDialog.vue'

export default Vue.extend({
  name: 'print-footer',
  components: { OptionsDialog },
  methods: {
    print() {
      window.print()
    },
    setOptions(options) {
      console.log(options)
    },
  },
})






















import Vue from 'vue'
import integratedInfoBase from './_integratedInfoBase.vue'

export default Vue.extend({
  name: 'integrated-info-panel',
  components: { integratedInfoBase },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
})

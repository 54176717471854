var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"block":"","color":"accent"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-database")]),_vm._v(" Создать Бэкап ")],1)]}}])},[_c('v-card',{attrs:{"max-width":"30vw"}},[_c('v-card-text',{staticClass:"text-center stark--text"},[_vm._v(" Этот инструмент сохранит снимок всех локальных пользовательских данных и содержимого LCP, включая данные, помеченные для удаления. Он создает файл .compcon, который можно загрузить из интерфейса «Загрузить резервную копию». "),_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{attrs:{"block":"","color":"accent"},on:{"click":function($event){return _vm.dataExport()}}},[_vm._v("Генерировать бэкап")])],1)],1)],1)],1),_c('v-col',[_c('v-menu',{attrs:{"top":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"block":"","color":"accent"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-database-refresh")]),_vm._v(" Загрузить Бэкап ")],1)]}}]),model:{value:(_vm.loadBackupDialog),callback:function ($$v) {_vm.loadBackupDialog=$$v},expression:"loadBackupDialog"}},[_c('v-card',{attrs:{"max-width":"30vw"}},[_c('v-card-text',{staticClass:"text-center stark--text"},[_vm._v(" Этот инструмент использует файл .compcon, созданный с помощью параметра «Создать резервную копию». Он заменит все локальные данные. "),_c('v-divider',{staticClass:"my-3"}),_c('v-file-input',{attrs:{"accept":".compcon","outlined":"","dense":"","hide-details":"","autofocus":"","placeholder":"Выберите файл массового экспорта COMP/CON","prepend-icon":"mdi-paperclip"},model:{value:(_vm.fileValue),callback:function ($$v) {_vm.fileValue=$$v},expression:"fileValue"}}),_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{attrs:{"block":"","color":_vm.importConfirm ? 'secondary' : 'accent',"disabled":!_vm.fileValue},on:{"click":function($event){_vm.importConfirm = true}},model:{value:(_vm.importConfirm),callback:function ($$v) {_vm.importConfirm=$$v},expression:"importConfirm"}},[_vm._v(" Подтвердить ")]),_c('v-btn',{staticClass:"my-2",attrs:{"block":"","small":"","color":"secondary","disabled":!_vm.importConfirm},on:{"click":_vm.importfile}},[_vm._v(" Перезаписать локальные данные ")])],1)],1)],1)],1),_c('v-col',[_c('v-menu',{attrs:{"top":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"block":"","color":"error darken-1"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Удалить все локальные данные ")],1)]}}]),model:{value:(_vm.deleteLocal),callback:function ($$v) {_vm.deleteLocal=$$v},expression:"deleteLocal"}},[_c('v-card',{attrs:{"max-width":"30vw"}},[_c('v-card-text',{staticClass:"text-center stark--text"},[_vm._v(" Это действие удалит все локальные данные COMP/CON. Облачные данные не будут затронуты. "),_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{attrs:{"block":"","color":_vm.deleteLocalConfirm ? 'secondary' : 'accent'},on:{"click":function($event){_vm.deleteLocalConfirm = true}},model:{value:(_vm.deleteLocalConfirm),callback:function ($$v) {_vm.deleteLocalConfirm=$$v},expression:"deleteLocalConfirm"}},[_vm._v(" Подтвердить ")]),_c('v-btn',{staticClass:"my-2",attrs:{"block":"","small":"","color":"error","disabled":!_vm.deleteLocalConfirm},on:{"click":function($event){return _vm.deleteAll(false)}}},[_vm._v(" Удалить все локальные данные ")])],1)],1)],1)],1),(_vm.username && _vm.username.length)?_c('v-col',[_c('v-menu',{attrs:{"top":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"block":"","color":"error darken-1"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cloud-alert")]),_vm._v(" Удалить все облачные данные ")],1)]}}],null,false,3392160618),model:{value:(_vm.deleteCloud),callback:function ($$v) {_vm.deleteCloud=$$v},expression:"deleteCloud"}},[_c('v-card',{attrs:{"max-width":"30vw"}},[_c('v-card-text',{staticClass:"text-center stark--text"},[_vm._v(" Это действие удалит все данные COMP/CON, хранящиеся в облаке под именем текущего пользователя ("+_vm._s(_vm.username)+"). Локальные данные не будут затронуты. "),_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{attrs:{"block":"","color":_vm.deleteCloudConfirm ? 'secondary' : 'accent'},on:{"click":function($event){_vm.deleteCloudConfirm = true}},model:{value:(_vm.deleteCloudConfirm),callback:function ($$v) {_vm.deleteCloudConfirm=$$v},expression:"deleteCloudConfirm"}},[_vm._v(" Подтвердить ")]),_c('v-btn',{staticClass:"my-2",attrs:{"block":"","small":"","color":"error","disabled":!_vm.deleteCloudConfirm},on:{"click":function($event){return _vm.deleteAll(true)}}},[_vm._v(" Удалить все облачные данные ")])],1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({name: 'cc-item-chip'})
export default class CCItemChip extends Vue {
  @Prop({type: Object, required: true})
  readonly item
}





















































import { Vue, Component, Prop } from 'vue-property-decorator'
@Component({ name: 'cc-active-card' })
export default class CCActiveCard extends Vue {
  @Prop({ type: String, required: false, default: 'primary' })
  readonly color: string
  @Prop({ type: String, required: true })
  readonly header: string
  @Prop({ type: String, required: false, default: '' })
  readonly subheader: string
  @Prop({ type: [String, Number], required: false, default: '' })
  readonly content: string | number
  @Prop({ type: [String, Number], required: false, default: '' })
  readonly cols: string | number
  @Prop({ type: Boolean })
  readonly collapsible: boolean
  @Prop({ type: Boolean })
  readonly startClosed: boolean
  @Prop({ type: Boolean })
  readonly prominent: boolean

  collapsed = false

  created(): void {
    this.collapsed = this.startClosed
  }
}
























import Vue from 'vue'
import Contributor from './Contributor.vue'

export default Vue.extend({
  name: 'statblock-item',
  components: { Contributor },
  props: {
    attr: { type: String, required: true },
    val: { type: Number, required: true },
    signed: { type: Boolean, required: false },
    contributors: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: '#bdbdbd',
    },
  },
})

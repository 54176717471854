






































































































import Vue from 'vue'
import Component from 'vue-class-component'
import CompendiumBrowser from '../components/CompendiumBrowser.vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { NpcClass } from '@/class'
import { accentInclude } from '@/classes/utility/accent_fold'

@Component({
  components: { CompendiumBrowser },
})
export default class NpcClasses extends Vue {
  private tier = 1
  private search = ''
  private tableHeight = 450
  public headers = [
    { text: 'Класс', align: 'left', value: 'Name' },
    { text: 'Роль', align: 'left', value: 'Role' },
    { text: 'ПЗ', align: 'left', value: 'HP' },
    { text: 'Броня', align: 'left', value: 'Armor' },
    { text: 'Структ.', align: 'left', value: 'Structure' },
    { text: 'Тепл.Макс.', align: 'left', value: 'Heatcap' },
    { text: 'Нагрузка', align: 'left', value: 'Stress' },
    { text: 'Уклонение', align: 'left', value: 'Evasion' },
    { text: 'Э-Защ', align: 'left', value: 'EDef' },
    { text: 'Скорость', align: 'left', value: 'Speed' },
    { text: 'Сенсоры', align: 'left', value: 'Sensor' },
    { text: 'Спасбросок', align: 'left', value: 'Save' },
    { text: 'К/М/С/И', align: 'left', value: 'Hase', sortable: false },
  ]
  private compendium = getModule(CompendiumStore, this.$store)
  public get classes(): NpcClass[] {
    return this.compendium.NpcClasses
  }

  public get fItems(): NpcClass[] {
    if (this.search) return this.classes.filter(x => accentInclude(x.Name, this.search))
    return this.classes
  }

  onResize(): void {
    this.tableHeight = window.innerHeight - 160
  }

  mounted(): void {
    this.onResize()
  }
}

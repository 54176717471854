




























import Vue from 'vue'
export default Vue.extend({
  name: 'encounter-group',
  data: () => ({
    groups: [
      { name: 'Нет', val: null, icon: 'mdi-close' },
      { name: 'Кампаниям', val: 'Campaign', icon: 'mdi-map' },
    ],
  }),
})

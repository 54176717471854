














































































































import Vue from 'vue'
import { Organization, OrgType } from '@/class'

export default Vue.extend({
  name: 'custom-reserve-panel',
  data: () => ({
    orgName: '',
    orgType: '',
    orgStart: '',
    orgDetails: '',
  }),
  computed: {
    orgTypes() {
      return Object.keys(OrgType)
        .map(k => OrgType[k as string])
        .sort() as OrgType[]
    },
  },
  methods: {
    add() {
      const o = new Organization({
        name: this.orgName,
        purpose: this.orgType,
        efficiency: this.orgStart === 'efficiency' ? 2 : 0,
        influence: this.orgStart === 'influence' ? 2 : 0,
        description: this.orgDetails,
        actions: '',
      })
      this.clear()
      this.$emit('add', o)
    },
    clear() {
      this.projectName = ''
      this.details = ''
      this.complicated = false
      this.finished = false
      this.costs = []
    },
  },
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',[_c('p',{staticClass:"text-center body-text",domProps:{"innerHTML":_vm._s(
        'Когда вы Налаживаете Связи, вы устанавливаете контакты, оказываете услуги, просите о помощи или заручаетесь поддержкой для выполнения какого-либо действия. <br /> Вы можете использовать ресурсы или помощь своих знакомых как <strong>Резерв</strong> для следующей миссии. <br/> Чтобы выполнить это действие, вы должны находиться в зоне действия связи или в месте, где вы можете провести старый добрый разговор лицом к лицу.'
      )}}),_c('v-divider',{staticClass:"mb-2"}),_c('div',{staticClass:"pt-2 heading h3 text-center"},[_vm._v(" Бросьте "),_c('v-icon',{attrs:{"large":"","color":"accent"}},[_vm._v("mdi-dice-d20")]),_vm._v("  и добавьте все соответствующие бонусы Триггеров, модификаторы и Точность ")],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"type":"number","label":"Результат броска","outlined":"","dense":"","hide-details":"","append-outer-icon":"mdi-plus-circle-outline","prepend-icon":"mdi-minus-circle-outline"},on:{"click:append-outer":function($event){_vm.skillRoll++},"click:prepend":function($event){_vm.skillRoll > 1 ? _vm.skillRoll-- : ''}},model:{value:(_vm.skillRoll),callback:function ($$v) {_vm.skillRoll=$$v},expression:"skillRoll"}})],1)],1),_c('v-slide-y-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.skillRoll),expression:"skillRoll"}],staticClass:"text-center flavor-text",attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[(_vm.skillRoll < 10)?_c('p',{staticClass:"font-weight-bold px-3",domProps:{"innerHTML":_vm._s(
              'Ваш собеседник поможет вам, но вы должны сделать одолжение или выполнить обещание <em>прямо сейчас</em>. Если вы этого не сделаете, он вам не поможет.'
            )}}):(_vm.skillRoll < 20)?_c('p',{staticClass:"font-weight-bold px-3",domProps:{"innerHTML":_vm._s(
              'Ваш контакт поможет вам, но вы должны сделать одолжение или выполнить обещание <em>после этого</em>. <br /> Если вы не выполните обещание, в следующий раз, когда вы получите этот результат для того же контакта, рассматривайте его как 9 или меньше.'
            )}}):_c('p',{staticClass:"font-weight-bold px-3",domProps:{"innerHTML":_vm._s(
              'Ваш собеседник поможет вам без всяких условий. <br /> Отнеситесь к этому результату как к 10-19 в следующий раз, когда вы получите его для того же контакта.'
            )}})]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-card',{staticClass:"ml-5 mr-5 mt-2",attrs:{"color":"panel"}},[_c('v-toolbar',{attrs:{"dark":"","dense":"","color":"action--downtime"}},[_c('v-toolbar-title',[_vm._v("Контакт")])],1),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Название контакта или организации","outlined":"","dense":"","hide-details":""},model:{value:(_vm.custom_name),callback:function ($$v) {_vm.custom_name=$$v},expression:"custom_name"}}),_c('v-textarea',{attrs:{"auto-grow":"","rows":"1","label":"Details"},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}})],1)],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("отменить")]),_c('v-spacer'),_c('v-btn',{attrs:{"large":"","tile":"","color":"primary","disabled":!_vm.skillRoll || !_vm.custom_name},on:{"click":_vm.addReserve}},[_vm._v(" добавить резерв ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
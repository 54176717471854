
































































import Vue from 'vue'
export default Vue.extend({
  name: 'combat-log-renderer',
  props: {
    state: {
      type: Object,
      required: true,
    },
  },
  methods: {
    undo(action) {
      this.state.UndoAction(action)
    },
  },
})

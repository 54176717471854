



















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'table-window-item',
})
export default class TableWindowItem extends Vue {
  @Prop({ type: String, required: true })
  title!: string
  @Prop({ type: String, required: false, default: '' })
  content: string
  @Prop({ type: Boolean })
  disabled?: boolean
  @Prop({ type: Boolean })
  otherBtn?: boolean
  @Prop({ type: Boolean })
  hidePrevious?: boolean
}



















































































































































































import Vue from 'vue'
import CopyMechDialog from './CopyMechDialog.vue'
import DeleteMechDialog from './DeleteMechDialog.vue'
import PrintDialog from '../../../components/PrintDialog.vue'

export default Vue.extend({
  name: 'mech-card',
  components: { CopyMechDialog, DeleteMechDialog, PrintDialog },
  props: {
    mech: {
      type: Object,
      required: true,
    },
  },
  computed: {
    loadoutWeapons() {
      const output = []
      for (const mount of this.mech.MechLoadoutController.ActiveLoadout.AllEquippableMounts(
        this.mech.Pilot.has('CoreBonus', 'cb_improved_armament'),
        this.mech.Pilot.has('CoreBonus', 'cb_integrated_weapon')
      )) {
        if (!mount.IsLocked) {
          let str = `${mount.Name}:`
          if (!mount.Weapons.length) str += ' ПУСТО'
          else {
            mount.Weapons.forEach((w, i) => {
              str += ` ${w.Name}`
              if (w.Mod) str += ` (${w.Mod.Name})`
              if (i + 1 < mount.Weapons.length) str += '/'
            })
          }
          output.push(str)
        }
      }
      return output
    },
    loadoutSystems() {
      return this.mech.MechLoadoutController.ActiveLoadout.AllActiveSystems.map(x => x.Name)
    },
  },
})



























































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import TableWindowItem from './_TableWindowItem.vue'
import CascadeCheck from './_CascadeCheck.vue'
import ResultData from './_structure_results.json'
import { Mech, MechLoadout, MechSystem } from '@/class'

@Component({
  name: 'structure-table',
  components: { TableWindowItem, CascadeCheck },
})
export default class CCSidebarView extends Vue {
  dialog = false
  show(): void {
    this.dialog = true
  }
  close(): void {
    this.window = 0
    this.rolls = []
    this.systemTraumaRoll = null
    this.destroyedSystem = null
    this.destroyedMount = null
    this.dialog = false
  }
  window = 0

  @Prop({ type: Object, required: true })
  mech!: Mech

  rolls = []
  resultData = ResultData
  systemTraumaRoll = null
  destroyedSystem = null
  destroyedMount = null
  results = [
    'Прямое Попадание',
    'Системная Травма',
    'Системная Травма',
    'Системная Травма',
    'Скользящий Удар',
    'Скользящий Удар',
  ]

  get loadout(): MechLoadout {
    return this.mech.MechLoadoutController.ActiveLoadout
  }
  get totalRolls(): number {
    return (this.mech.CurrentStructure - this.mech.MaxStructure) * -1
  }
  get resultWindow(): number {
    if (this.rolls.filter(x => x === 1).length > 1) return 4
    switch (Math.min(...this.rolls)) {
      case 6:
      case 5:
        return 1
      case 4:
      case 3:
      case 2:
        if (!this.destroyableMounts.length && !this.destroyableSystems.length) return 3
        return 2
      case 1:
        return this.mech.CurrentStructure <= 1 ? 4 : 3
    }
    return 4
  }

  get destroyableMounts(): { name: string; index: number }[] {
    return this.loadout
      .AllMounts(
        this.mech.Pilot.has('CoreBonus', 'cb_improved_armament'),
        this.mech.Pilot.has('CoreBonus', 'cb_integrated_weapon')
      )
      .filter(x => x.Weapons.some(w => !w.Destroyed && !(w.IsLimited && w.Uses === 0)))
      .map((m, i) => ({ name: m.Name, index: i }))
  }

  get destroyableSystems(): MechSystem[] {
    return this.loadout.AllActiveSystems.filter(
      x => !x.IsIndestructible && !x.Destroyed && !(x.IsLimited && x.Uses === 0)
    )
  }

  rollRandom(): number {
    return Math.floor(Math.random() * 6) + 1
  }

  applyGlancingBlow(): void {
    if (!this.mech.Conditions.includes('Ослабление')) this.mech.Conditions.push('Ослабление')
    this.close()
  }

  applyDirectHit(): void {
    if (!this.mech.Conditions.includes('Ошеломление')) this.mech.Conditions.push('Ошеломление')
    this.close()
  }

  applyDestroyed(): void {
    this.mech.Destroy()
    this.close()
  }

  applySystemTrauma(): void {
    if (this.systemTraumaRoll > 3) {
      let s = this.loadout.Systems.find(x => x.ID === this.destroyedSystem)
      if (!s) {
        s = this.loadout.IntegratedSystems.find(x => x.ID === this.destroyedSystem)
      }
      s.Destroy()
    } else {
      const m = this.loadout.AllMounts(
        this.mech.Pilot.has('CoreBonus', 'cb_improved_armament'),
        this.mech.Pilot.has('CoreBonus', 'cb_integrated_weapon')
      )[this.destroyedMount]
      m.Weapons.forEach(w => {
        w.Destroy()
      })
    }
    this.close()
  }
}























import { Vue, Component, Prop } from 'vue-property-decorator'
import { DamageType } from '@/class'

@Component({
  name: 'cc-damage-type-picker',
})
export default class CCStringEdit extends Vue {
  @Prop({ type: Array, required: false, default: () => [] })
  readonly allowedTypes: string[]

  dialog = false
  availableTypes = []
  selected = ''

  created(): void {
    this.availableTypes = this.allowedTypes.length
      ? this.damageTypes().filter(x => this.allowedTypes.includes(x))
      : this.damageTypes().filter(x => x !== 'Variable')
  }

  damageTypes(): string[] {
    return Object.keys(DamageType)
      .map(k => DamageType[k as any])
      .sort() as string[]
  }

  damageTypeValueToKey(t: string): string {
    return Object.keys(DamageType).find(key => DamageType[key] === <DamageType>t)
  }

  show(): void {
    this.dialog = true
  }
  hide(): void {
    this.dialog = false
  }

  select(t: string): void {
    this.$emit('select', t)
    this.hide()
  }
}

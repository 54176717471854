





































































import Vue from 'vue'
export default Vue.extend({
  name: 'npc-item-menu',
  props: {
    item: { type: Object, required: true },
  },
  methods: {
    upgradeTier() {
      this.item.Tier++
      this.$emit('recalc')
    },
    downgradeTier() {
      this.item.Tier--
      this.$emit('recalc')
    },
    save(prop, newName) {
      this.$set(this.item, prop, newName)
    },
  },
})

































import Vue from 'vue'
export default Vue.extend({
  name: 'requirement-item',
  props: {
    license: {
      type: Object,
      required: true,
    },
  },
})

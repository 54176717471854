




import { Vue, Component, Prop } from 'vue-property-decorator'
@Component({ name: 'cc-item-card' })
export default class CCItemCard extends Vue {
  @Prop({ type: Object, required: true })
  readonly item

  @Prop({ type: Boolean })
  readonly notes

  get componentLoader(): any {
    if (!this.item) {
      return null
    }
    return () => {
      try {
        return import(`./_${this.item.ItemType}Card.vue`)
      } catch (error) {
        console.error(`Невозможно загрузить компонент ${this.item.ItemType}`)
        return null
      }
    }
  }
}

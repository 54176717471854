










































import activePilot from '@/features/pilot_management/mixins/activePilot'
import { Auth } from 'aws-amplify'
import vueMixins from '@/util/vueMixins'

export default vueMixins(activePilot).extend({
  name: 'ident-block',
  data: () => ({
    pilotStatuses: [
      { text: 'АКТИВЕН', value: 'АКТИВЕН' },
      { text: 'НЕАКТИВЕН', value: 'НЕАКТИВЕН' },
      { text: 'В ОТСТАВКЕ', value: 'В ОТСТАВКЕ' },
      { text: 'ПРОПАЛ БЕЗ ВЕСТИ', value: 'ПРОПАЛ БЕЗ ВЕСТИ' },
      { text: 'УБИТ В БОЮ', value: 'УБИТ В БОЮ' },
      { text: 'НЕИЗВЕСТНО', value: 'НЕИЗВЕСТНО' },
    ],
    noteColor: '',
    notification: '',
    syncing: false,
    currentAuthedUser: null,
  }),
  async mounted() {
    await Auth.currentAuthenticatedUser().then(res => {
      this.currentAuthedUser = !!res.username
    })
  },
  methods: {
    statusColor(): string {
      switch (this.pilot.Status.toLowerCase()) {
        case 'активен':
          return 'success'
          break
        case 'пропал без вести':
        case 'убит в бою':
          return 'error'
        default:
          return 'text'
          break
      }
    },
  },
})

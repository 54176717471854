



















































































import Vue from 'vue'
import { Reserve } from '@/class'
export default Vue.extend({
  name: 'scrounge-barter',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    skillRoll: '',
    custom_name: '',
    details: '',
    choices: [
      'Он был украден, вероятно, у того, кто его ищет',
      'Он вышел из строя, старый, грязный или неисправный',
      'Кто-то еще владеет этим прямо сейчас и не отдаст без силы или убеждения',
    ],
    choice: 0,
    trades: ['Время', 'Достоинство', 'Репутация', 'Здоровье, комфорт и благополучие'],
    trade: 0,
  }),
  methods: {
    addReserve() {
      const nr = new Reserve({
        id: 'reserve_scroungebarter',
        type: 'Ресурс',
        name: 'Актив',
        label: '',
        description: '',
        resource_note: this.details,
        resource_name: this.custom_name,
        resource_cost: '',
        used: false,
        consumable: false,
      })
      if (this.skillRoll < 10) nr.ResourceCost = this.choices[this.choice]
      else if (this.skillRoll < 20)
        nr.ResourceCost = `Цена за получение этого актива: ${this.trades[
          this.trade
        ].toLowerCase()}`
      this.pilot.ReservesController.AddReserve(nr)
      this.close()
    },
    close() {
      this.choice = 0
      this.trade = 0
      this.skillRoll = ''
      this.custom_name = ''
      this.details = ''
      this.$emit('close')
    },
  },
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.pilot.Status === 'УБИТ В БОЮ' || _vm.pilot.IsDead)?_c('v-alert',{attrs:{"prominent":"","dense":"","outlined":"","color":"error"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"slot":"prepend","size":"80"},slot:"prepend"},[_vm._v("mdi-skull")]),_c('div',{class:("heading " + (_vm.$vuetify.breakpoint.mdAndUp ? 'h1' : 'h3') + " pb-2 text-center")},[_vm._v(" УБИТ В БОЮ ")]),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{style:(_vm.$vuetify.breakpoint.mdAndUp
            ? 'position: absolute; bottom: -3px; right: -3px'
            : 'text-align: center')},[_c('v-menu',{attrs:{"offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","x-small":"","outlined":""}},on),[_vm._v("Флэш-клонировать пилота")])]}}],null,false,55201885)},[_c('cc-confirmation',{attrs:{"content":"Это склонирует выбранного пилота. Клонированные персонажи не могут присоединиться к миссии в процессе и получают случайную причуду. Дополнительное клонирование или перепись субъективности добавляет дополнительные причуды."},on:{"confirm":_vm.setQuirk}})],1),_c('v-menu',{attrs:{"offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"fadeSelect ml-3",attrs:{"color":"pimary","x-small":""}},on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-reload")]),_vm._v(" Вернуть ")],1)]}}],null,false,3447007966)},[_c('cc-confirmation',{attrs:{"content":"Это восстановит выбранного пилота и очистит статусы Убит в бою и Нокаут."},on:{"confirm":function($event){return _vm.pilot.Restore()}}})],1)],1)])],1):_vm._e(),(_vm.pilot.Quirks.length && !_vm.hideQuirks)?_c('div',[_c('div',{staticClass:"flavor-text font-weight-bold stark--text"},[_vm._v("ПРИЧУДЫ КЛОНА")]),_vm._l((_vm.pilot.Quirks),function(q,i){return _c('v-row',{key:("clone_quirk_" + i),attrs:{"dense":"","align":"start"}},[_c('v-col',[_c('v-alert',{attrs:{"icon":"mdi-dna","prominent":"","dense":"","color":"primary","outlined":""}},[_c('v-textarea',{staticClass:"body-text",attrs:{"value":q,"dense":"","hide-details":"","rows":"1","auto-grow":"","readonly":_vm.readonly},on:{"change":function($event){return _vm.updateQuirk(i, $event)},"blur":function($event){return _vm.pilot.SaveController.save()}}})],1)],1),(!_vm.readonly)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('cc-tooltip',{attrs:{"content":"Убрать причуду клона"}},[_c('v-btn',_vm._g({staticClass:"fadeSelect",attrs:{"icon":""},on:{"click":function($event){return _vm.pilot.RemoveQuirk(i)}}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("close")])],1)],1)]}}],null,true)})],1):_vm._e()],1)})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }



































import Vue from 'vue'

import HomeCard from './components/HomeCard.vue'

export default Vue.extend({
  name: 'home',
  components: { HomeCard },
})

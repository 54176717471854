

























































































































































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

export default Vue.extend({
  name: 'npc-feature-select-menu',
  props: {
    pilot: { type: Object, required: true },
  },
  data: () => ({
    dialog: false,
    featureSet: 'all',
    ignoreLimit: false,
    allowDupes: false,
  }),
  computed: {
    currentSelection() {
      switch (this.featureSet) {
        case 'all':
          return this.pilot.BondController.Bond.Name
        case 'assigned':
          return 'All Assigned'
        default:
          return this.Bonds.find(x => x.ID === this.featureSet).Name
      }
    },
    shownPowers() {
      if (!this.pilot.BondController.TotalPowerSelections && !this.ignoreLimit) {
        if (this.featureSet === 'all')
          return this.pilot.BondController.Bond.Powers.filter(x =>
            this.pilot.BondController.BondPowers.some(y => y.name === x.name)
          )
        if (this.featureSet === 'assigned') return this.pilot.BondController.BondPowers
        return getModule(CompendiumStore, this.$store)
          .Bonds.find(x => x.ID === this.featureSet)
          .Powers.filter(x => this.pilot.BondController.BondPowers.some(y => y.name === x.name))
      }

      if (this.featureSet === 'all') return this.pilot.BondController.Bond.Powers
      if (this.featureSet === 'assigned') return this.pilot.BondController.BondPowers

      return getModule(CompendiumStore, this.$store).Bonds.find(x => x.ID === this.featureSet)
        .Powers
    },
    Bonds() {
      return getModule(CompendiumStore, this.$store).Bonds.map(x => ({
        Name: x.Name,
        ID: x.ID,
      }))
    },
  },
  methods: {
    hasPower(bond) {
      return this.pilot.BondController.BondPowers.some(y => y.name === bond.name)
    },
    resetPowers() {
      this.pilot.BondController.BondPowers.splice(0, this.pilot.BondController.BondPowers.length)
    },
  },
})

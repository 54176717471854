



















import Vue from 'vue';
import "external-svg-loader";

export default Vue.extend({
  name: 'cc-logo-splash',
  props: {
    source: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    corsSafe: false
  }),
  computed: {
    iconColor(): string {
      return this.color || this.source.Color
    },
    isSvg(): boolean {
      return this.source.isSvg
    },
    isCorsSafe(): boolean {
      return this.source.isCorsSafe
    }
  },
})














































































































































































import Vue from 'vue'
import { EncounterSide } from '@/class'

export default Vue.extend({
  name: 'slide-item',
  props: {
    actor: {
      type: Object,
      required: true,
    },
    complete: {
      type: Boolean,
    },
    defeated: {
      type: Boolean,
    },
  },
  computed: {
    color() {
      if (this.actor.Frame) return 'secondary'
      if (this.actor.Side === EncounterSide.Ally) return 'blue'
      if (this.actor.Side === EncounterSide.Enemy) return 'primary'
      return 'grey darken-2'
    },
  },
})

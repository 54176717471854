export default {
  frame: '#673AB7',
  system: '#58b434',
  'mech-system': '#58b434',
  'pilot-gear': '#58b434',
  trait: '#673AB7',
  protocol: '#1b6361',
  mod: '#00BFA5',
  'weapon-mod': '#00BFA5',
  structure: '#F50057',
  hp: '#0284FF',
  armor: '#82B1FF',
  'pilot-armor': '#82B1FF',
  heat: '#FF7043',
  stress: '#FF3D00',
  heatcap: '#EF5350',
  dangerzone: '#FF1744',
  repcap: '#8E8E8E',
  overcharge: '#F50057',
  corepower: '#00BFA5',
  background: '#f4f4f4',

  'action--move': '#C62828',
  'action--full': '#283593',
  'action--quick': '#3949AB',
  'action--overcharge': '#F50057',
  'action--free': '#00695C',
  'action--protocol': '#007676',
  'action--tech': '#802475',
  'action--invade': '#222222',

  'damage--kinetic': '#757575',
  'damage--energy': '#29B6F6',
  'damage--explosive': '#FFA726',
  'damage--burn': '#B71C1C',
  'damage--heat': '#FF7043',
  'damage--variable': '#D500F9',

  'reserve--resource': '#00695C',
  'reserve--tactical': '#827717',
  'reserve--mech': '#BF360C',
  'reserve--project': '#5D4037',
  'reserve--custom': '#D84315',

  'role--striker': '#bd3f39',
  'role--defender': '#d67e08',
  'role--controller': '#398ad6',
  'role--support': '#6aa84f',
  'role--artillery': '#a64d79',
  'role--biological': '#7e52a3',

  'npc--feature': '#991E2A',
  'npc--trait': '#991E2A',
  'npc--system': '#58b434',
  'npc--weapon': '#212121',
  'npc--tech': '#007674',
  'npc--reaction': '#512DA8',

  enemy: '#C62828',
  ally: '#1661b8',
  neutral: '#455A64',

  // brand colors
  patreon: '#FF424D'
}

































































import { WeaponSize } from '@/class'
import Vue from 'vue'
import WeaponAttack from '../../components/_WeaponAttack.vue'

export default Vue.extend({
  name: 'barrage-dialog',
  components: { WeaponAttack },
  props: {
    mech: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    state() {
      return this.mech.Pilot.State
    },
    items() {
      return this.state.BarrageSelections
    },
    mounts() {
      return this.state.BarrageMounts
    },
  },
  methods: {
    hasAux(mount, primary) {
      const auxes = mount.Weapons.filter(x => x.Size === WeaponSize.Aux)
      if (!auxes.length) return false
      const unusedAux = auxes.filter(x => x !== primary)
      if (!unusedAux.length) return false
      const candidate = unusedAux[0]
      if (this.items.some(x => x === candidate)) return false
      return candidate || false
    },
    reset() {
      for (let i = 0; i < this.items.length; i++) {
        this.$refs[`main_${i}`].reset()
      }
      for (let i = 0; i < this.auxes.length; i++) {
        this.$refs[`aux_${i}`].reset()
      }
    },
    confirm(): void {
      this.mech.Pilot.State.ClearBarrageSelections()
      this.dialog = false
      this.$emit('confirm')
    },
    show(): void {
      this.dialog = true
    },
    hide(): void {
      this.mech.Pilot.State.ClearBarrageSelections()
      this.dialog = false
      this.$emit('close')
    },
  },
})

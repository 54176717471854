





























































































































































































import Vue from 'vue'
import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'

export default Vue.extend({
  name: 'ram-dialog',
  components: { ActionDetailExpander },
  props: {
    used: { type: Boolean },
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    accuracy: 0,
    difficulty: 0,
    attackRoll: '',
    succeeded: false,
    failed: false,
    complete: false,
    actionCost: false,
    actionFree: false,
  }),
  watch: {
    used: {
      immediate: true,
      deep: true,
      handler: function(newval) {
        if (!newval) this.init()
      },
    },
  },
  methods: {
    select(action) {
      this.$emit('use', this.actionFree)
      return !action
    },
    registerAttackRoll(roll) {
      Vue.set(this, 'attackRoll', roll)
      Vue.nextTick().then(() => this.$forceUpdate())
    },
    init() {
      this.accuracy = 0
      this.difficulty = 0
      this.attackRoll = ''
      this.succeeded = false
      this.failed = false
      this.actionCost = false
      this.actionFree = false
    },
  },
})

























import Vue from 'vue'
import { Mech } from '@/class'
export default Vue.extend({
  name: 'mech-table',
  props: {
    mechs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getMechStatus(m: Mech) {
      if (m.Destroyed) return ['DESTROYED', 'red--text text--darken-2']
      if (m.ReactorDestroyed) return ['REACTOR DESTROYED', 'error--text']
      if (m.IsActive) return ['ACTIVE', 'success--text']
      return ['STANDBY', 'subtle--text text--darken-2']
    },
  },
})

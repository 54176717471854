













import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ name: 'cc-compendium-page-btn' })
export default class CompendiumPageButton extends Vue {
  @Prop({ type: String, required: true })
  readonly name!: string

  @Prop({ type: String, required: false, default: '' })
  readonly icon: string

  @Prop({ type: String, required: false, default: '' })
  readonly cols?: string

  @Prop({ type: String, required: false, default: '' })
  readonly lg?: string

  @Prop({ type: String, required: false, default: '' })
  readonly md?: string

  @Prop({ type: String, required: false, default: '' })
  readonly sm?: string

  @Prop({ type: String, required: false, default: 'primary' })
  readonly color: string
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',[_c('p',{staticClass:"text-center body-text",domProps:{"innerHTML":_vm._s(
        'Вы пытаетесь отсрочить расплату, расширить окно возможностей или просто выиграть немного времени и передышки для себя и своей группы. Возможно, вы пытаетесь укрыться от жары, выжить, застряв в дикой местности, или отвлечь внимание, чтобы другой план достиг своей кульминации. <br /> Вы можете использовать это отвлечение или выигранное время как <strong>Резерв</strong> для следующей миссии.'
      )}}),_c('v-divider',{staticClass:"mb-2"}),_c('div',{staticClass:"pt-2 heading h3 text-center"},[_vm._v(" Бросьте "),_c('v-icon',{attrs:{"large":"","color":"accent"}},[_vm._v("mdi-dice-d20")]),_vm._v("  и добавьте все соответствующие бонусы Триггеров, модификаторы и Точность ")],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"type":"number","label":"Результат броска","outlined":"","dense":"","hide-details":"","append-outer-icon":"mdi-plus-circle-outline","prepend-icon":"mdi-minus-circle-outline"},on:{"click:append-outer":function($event){_vm.skillRoll++},"click:prepend":function($event){_vm.skillRoll > 1 ? _vm.skillRoll-- : ''}},model:{value:(_vm.skillRoll),callback:function ($$v) {_vm.skillRoll=$$v},expression:"skillRoll"}})],1)],1),_c('v-slide-y-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.skillRoll),expression:"skillRoll"}],staticClass:"text-center flavor-text",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[(_vm.skillRoll < 10)?_c('p',{staticClass:"font-weight-bold px-3",domProps:{"innerHTML":_vm._s(
              'Вы можете выиграть только немного времени, и только в том случае, если решительные меры будут приняты <em>прямо сейчас.</em> В противном случае то, от чего вы пытаетесь уберечься, настигнет вас.'
            )}}):(_vm.skillRoll < 20)?_c('p',{staticClass:"font-weight-bold px-3",domProps:{"innerHTML":_vm._s(
              'Вы выигрываете достаточно времени, но ситуация становится опасной или безвыходной. В следующий раз, когда вы получите этот результат для той же ситуации, рассматривайте его как 9 или меньше.'
            )}}):_c('p',{staticClass:"font-weight-bold px-3",domProps:{"innerHTML":_vm._s(
              'Вы выигрываете столько времени, сколько вам нужно, до следующего сеанса отдыха. В следующий раз, когда вы получите этот результат для той же ситуации, рассматривайте его как 10-19.'
            )}}),_c('v-card',{staticClass:"ml-5 mr-5",attrs:{"color":"panel","flat":"","tile":""}},[_c('v-toolbar',{attrs:{"dark":"","dense":"","color":"action--downtime"}},[_c('v-toolbar-title',{staticClass:"heading h2"},[_vm._v("Купленное время")])],1),_c('v-card-text',[_c('v-textarea',{attrs:{"color":"accent","auto-grow":"","rows":"1","label":"Подробности","filled":""},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}})],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("отменить")]),_c('v-spacer'),_c('v-btn',{attrs:{"large":"","tile":"","color":"primary","disabled":_vm.skillRoll === '' || _vm.details === ''},on:{"click":function($event){return _vm.addReserve()}}},[_vm._v(" добавить резерв ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }




















































































































































































































































































import Vue from 'vue'
import { DiceRoller } from '@/classes/dice/DiceRoller'

export default Vue.extend({
  name: 'cc-dice-menu',
  props: {
    title: { type: String, required: false },
    preset: { type: String, required: false },
    presetAccuracy: { type: Number, required: false, default: 0 },
    overkill: { type: Boolean },
    critical: { type: Boolean },
    autoroll: { type: Boolean },
  },
  data: () => ({
    menu: false,
    moreDice: false,
    dice: [],
    accRolls: [],
    flat: 0,
    result: null,
    accuracy: 0,
    accTotal: 0,
  }),
  computed: {
    accString() {
      if (this.accuracy > 0) return `<b>${this.accuracy}</b>&nbsp;&nbsp;ACCURACY`
      else return `<b>${Math.abs(this.accuracy)}</b>&nbsp;&nbsp;DIFFICULTY`
    },
    overkillRolls() {
      if (!this.result) return 0
      return this.result.map(x => x.overkill).reduce((a, b) => a + b, 0)
    },
    total() {
      if (!this.result) return parseInt(this.flat)
      return (
        this.result
          .flatMap(x =>
            x.rolls.map((y, i) => {
              return x.class[i] === 'high' ? y : 0
            })
          )
          .reduce((a, b) => a + b, 0) +
        parseInt(this.flat) +
        parseInt(this.accTotal)
      )
    },
  },
  watch: {
    menu() {
      if (!this.autoroll) this.reset()
    },
    presetAccuracy() {
      if (this.autoroll) {
        this.accuracy=this.presetAccuracy
        this.rollAccuracy()
        this.commit()
      }
    },
  },
  mounted() {
    this.reset()
    if (this.autoroll) this.$nextTick(this.autoRoll)
  },
  methods: {
    addDice(sides) {
      this.result = null
      const idx = this.dice.findIndex(x => x.sides === sides)
      if (idx > -1) this.dice[idx].count++
      else this.dice.push({ sides, count: 1 })
    },
    removeDice(sides) {
      this.result = null
      const idx = this.dice.findIndex(x => x.sides === sides)
      this.dice[idx].count--
      if (this.dice[idx].count < 1) this.dice.splice(idx, 1)
    },
    removeMod() {
      this.result = null
      if (this.accuracy > 0) this.accuracy--
      else this.accuracy++
    },
    autoRoll() {
      this.roll()
      this.commit()
    },
    roll() {
      this.result = this.dice.map(x => {
        const dRoll = DiceRoller.rollDamage(`${x.count}d${x.sides}`, this.critical, this.overkill)
        return {
          sides: x.sides,
          rolls: dRoll.rawDieRolls,
          class: dRoll.rollClassifications,
          overkill: dRoll.overkillRerolls,
        }
      })
      this.rollAccuracy()
    },
    rollAccuracy() {
      this.accTotal=0
      if (this.accuracy) {
        this.accRolls = DiceRoller.rollDamage(
          `${Math.abs(this.accuracy)}d${6}`,
          false,
          false
        ).rawDieRolls
        this.accTotal = Math.max(...this.accRolls) * (this.accuracy > 0 ? 1 : -1)
      }

    },
    reset() {
      this.clear()
      if (this.preset) {
        const arr = this.preset.split(/\+|\-/)
        arr.forEach(e => {
          if (e.includes('d')) {
            const dice = e.split('d')
            this.dice.push({ sides: dice[1], count: dice[0] })
          } else this.flat += parseInt(e)
        })
      }
      this.accuracy = this.presetAccuracy
    },
    clear() {
      this.dice.splice(0, this.dice.length)
      this.accRolls.splice(0, this.dice.length)
      this.flat = 0
      this.result = null
      this.accuracy = 0
      this.accTotal = 0
    },
    commit() {
      this.$emit('commit', {
        total: this.total,
        overkill: this.overkillRolls,
      })
      this.menu = false
    },
  },
})
































































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import _ from 'lodash'
import { Pilot } from '@/class'

export default Vue.extend({
  name: 'clone-dialog',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    quirk: null,
  }),
  methods: {
    show() {
      this.$refs.dialog.show()
    },
    hide() {
      this.quirk = null
      this.$refs.dialog.hide()
    },
    rollQuirk() {
      const compendium = getModule(CompendiumStore, this.$store)
      this.quirk = _.sample(compendium.Tables.quirks)
    },
    clonePilot() {
      const newPilot = Pilot.Deserialize(Pilot.Serialize(this.pilot))
      newPilot.GroupController.reset()
      newPilot.CloudController.reset()
      newPilot.RenewID()
      if (!this.pilot.Callsign.includes('※')) this.pilot.Callsign += '※'
      if (!this.pilot.Callsign.includes('※')) this.pilot.Name += '※'
      this.pilot.Heal()
      this.pilot.AddQuirk(this.quirk)
      for (const mech of newPilot.Mechs) {
        mech.RenewID()
      }
      newPilot.GroupController.SortIndex = -1
      this.$store.dispatch('addPilot', newPilot)
      this.hide()
    },
    copyPilot() {
      const newPilot = Pilot.Deserialize(Pilot.Serialize(this.pilot))
      newPilot.GroupController.reset()
      newPilot.CloudController.reset()
      newPilot.RenewID()
      newPilot.Callsign += '″'
      newPilot.Name += ' (КОПИЯ)'
      newPilot.Status = 'АКТИВЕН'
      for (const mech of newPilot.Mechs) {
        mech.RenewID()
      }
      newPilot.GroupController.SortIndex = -1
      this.$store.dispatch('addPilot', newPilot)
      this.hide()
    },
  },
})















import Vue from 'vue'
import Settings from './Settings.vue'
import Log from './Log.vue'

export default Vue.extend({
  name: 'options',
  components: { Settings, Log },
  data: () => ({
    tab: 0,
  }),
})

































































































import Vue from 'vue'
import _ from 'lodash'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { Rules, MechWeapon } from '@/class'
import { flavorID } from '@/io/Generators'
import { Bonus } from '@/classes/components/feature/bonus/Bonus'

export default Vue.extend({
  name: 'weapon-selector',
  props: {
    weaponSlot: {
      type: Object,
      required: true,
    },
    mech: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    headers: [
      { text: 'Источник', align: 'left', value: 'Source' },
      { text: 'Оружие', align: 'left', value: 'Name' },
      { text: 'Лицензия', align: 'left', value: 'LicenseString' },
      { text: 'Размер', align: 'left', value: 'SizeInt' },
      { text: 'Тип', align: 'left', value: 'WeaponType' },
      { text: 'Дальность', align: 'left', value: 'Range[0].Max' },
      { text: 'Урон', align: 'left', value: 'Damage[0].Max' },
      { text: 'СП', align: 'left', value: 'SP' },
      { text: '', align: 'center', value: 'Equip' },
    ],
    weapons: [],
    showUnlicensed: false,
    showOverSP: false,
  }),
  computed: {
    freeSP(): number {
      return this.weaponSlot.Weapon
        ? this.mech.FreeSP + this.weaponSlot.Weapon.SP
        : this.mech.FreeSP
    },
    availableWeapons(): MechWeapon[] {
      const fittings = Rules.MountFittings[this.weaponSlot.Size]
      // filter by fitting size
      console.log(this.weapons);
      console.log(fittings);
      let i = this.weapons.filter(x => fittings.includes(x.Size) && !x.IsHidden && !x.IsExotic)

      // filter already equipped
      if (this.weaponSlot.Weapon) i = i.filter(x => x.ID !== this.weaponSlot.Weapon.ID)

      // filter ai
      if (
        this.mech.MechLoadoutController.ActiveLoadout.AICount >=
        1 + Bonus.get('ai_cap', this.mech)
      ) {
        i = i.filter(x => !x.IsAI)
      }

      if (!this.showUnlicensed) {
        i = i.filter(
          x => !x.LicenseLevel || this.mech.Pilot.has('License', x.License, x.LicenseLevel)
        )
      }

      i = i.concat(
        this.mech.Pilot.SpecialEquipment.filter(
          x => x.ItemType === 'MechWeapon' && fittings.includes(x.Size)
        )
      )

      // filter unique
      i = i.filter(
        x =>
          !this.mech.MechLoadoutController.ActiveLoadout.UniqueWeapons.map(y => y.ID).includes(x.ID)
      )

      return _.sortBy(i, ['Source', 'Name'])
    },
  },
  created() {
    const compendium = getModule(CompendiumStore, this.$store)
    this.weapons = compendium.MechWeapons.filter(x => x.Source)
  },
  methods: {
    fID(template: string): string {
      return flavorID(template)
    },
  },
})

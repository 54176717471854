























































































































































































































import activePilot from '@/features/pilot_management/mixins/activePilot'
import vueMixins from '@/util/vueMixins'
import CloneBlock from '@/features/pilot_management/PilotSheet/sections/info/components/CloneBlock.vue'
import DestroyedAlert from '../components/DestroyedAlert.vue'

export default vueMixins(activePilot).extend({
  name: 'pilot-block',
  components: { CloneBlock, DestroyedAlert },
  data: () => ({
    showReserves: false,
  }),
  methods: {
    expandAll(len: number, key: string, expand: boolean) {
      for (let i = 0; i < len; i++) {
        const k = key + i
        this.$refs[k][0].collapsed = expand
      }
    },
  },
})




































import Vue from 'vue'
export default Vue.extend({
  name: 'hase-pips',
  props: {
    mech: {
      type: Object,
      required: true,
    },
    attr: {
      type: String,
      required: true,
    },
    val: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
  },
  data: () => ({
    // TODO: get from rules
    maxHASE: 6,
  }),
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cc-sidebar-view',{attrs:{"cols":"3"}},[_c('div',{attrs:{"slot":"sidebar"},slot:"sidebar"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","dense":"","hide-details":"","outlined":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('roster-group',{on:{"set":function($event){_vm.grouping = $event}}})],1)],1),_c('v-divider',{staticClass:"my-2 "}),_c('v-data-table',{staticClass:"transparent",staticStyle:{"min-width":"100%"},attrs:{"dense":"","items":_vm.npcs,"headers":_vm.headers,"group-by":_vm.grouping,"search":_vm.search,"no-results-text":"Не найдено НИПов","no-data-text":"Нет сохраненных НИПов","disable-pagination":"","hide-default-footer":"","hide-default-header":"","calculate-widths":""},scopedSlots:_vm._u([{key:"group.header",fn:function(h){return [_c('div',{staticClass:"primary sliced"},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-chevron-right")]),(h.group && h.group !== 'null')?_c('span',{staticClass:"heading white--text text-uppercase"},[(Array.isArray(h.group))?_c('span',{domProps:{"innerHTML":_vm._s(h.group.join(', '))}}):_c('span',{domProps:{"innerHTML":_vm._s(h.group)}})]):_c('span',[_vm._v("НЕТ")])],1)]}},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"accent--text heading clickable ml-n2",on:{"click":function($event){_vm.$vuetify.goTo(("#" + (_vm.generateNpcElementId(item))), {
              duration: 150,
              easing: 'easeInOutQuad',
              offset: 25,
              container: _vm.$el.closest('.v-dialog--active'),
            })}}},[_c('v-icon',{domProps:{"innerHTML":_vm._s(item.Class.RoleIcon)}}),_c('v-icon',{attrs:{"left":""},domProps:{"innerHTML":_vm._s(("cci-rank-" + (item.Tier)))}}),_vm._v(" "+_vm._s(item.Name)+" ")],1)]}}])})],1),_c('br'),(!_vm.npcs.length)?_c('div',{staticClass:"subtle--text heading h2 text-center"},[_vm._v(" // НЕТ ДОСТУПНЫХ НИП // ")]):_vm._e(),_vm._l((_vm.npcs),function(npc,i){return _c('v-row',{key:((npc.ID) + "_" + i),attrs:{"id":_vm.generateNpcElementId(npc)}},[_c('v-col',{staticClass:"pl-0 mb-2"},[_c('npc-panel',{attrs:{"npc":npc}},[_c('div',[_c('v-divider',{staticClass:"my-2"}),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"mt-n6",attrs:{"cols":"9"}},[_c('v-btn',{attrs:{"block":"","x-large":"","tile":"","outlined":"","color":_vm.colorForSide(_vm.side).toLowerCase(),"dark":""},on:{"click":function($event){return _vm.$emit('select', { npc: npc, side: _vm.side })}}},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Добавить НИП ")],1)],1),_c('v-col',[_c('v-select',{attrs:{"label":"Как...","outlined":"","dense":"","items":['Противник', 'Союзник', 'Нейтральный']},model:{value:(_vm.side),callback:function ($$v) {_vm.side=$$v},expression:"side"}})],1)],1)],1)])],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }

































































































































































































































import Vue from 'vue'
import ActiveModInset from './components/_ActiveModInset.vue'
import WeaponActivators from './components/_WeaponActivators.vue'
import EquipmentOptions from '../mech_loadout/components/_EquipmentOptions.vue'
import EquipmentHeader from '../mech_loadout/components/_EquipmentHeader.vue'
import { WeaponSize, PilotTalent, WeaponType, Range, Damage } from '@/class'

export default Vue.extend({
  name: 'active-weapon-card',
  components: {
    WeaponActivators,
    ActiveModInset,
    EquipmentOptions,
    EquipmentHeader,
  },
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },
    mech: {
      type: Object,
      required: true,
      default: null,
    },
    mount: {
      type: Object,
      required: true,
    },
    rest: {
      type: Boolean,
    },
  },
  data: () => ({
    tab: 0,
    hide: false,
  }),
  computed: {
    color() {
      return this.mech.Frame.Manufacturer.GetColor(this.$vuetify.theme.dark)
    },
    // armoryLevel() {
    //   if (this.item.Size !== WeaponSize.Main || this.item.WeaponType === WeaponType.Melee) return 0
    //   const tal = this.mech.pilot.TalentsController.Talents.find(
    //     (x: PilotTalent) => x.Talent.ID === 't_walking_armory'
    //   )
    //   if (!tal) return 0
    //   return tal.Rank
    // },
    getRange() {
      if (!this.item) return []
      return Range.CalculateRange(this.item, this.mech)
    },
    getDamage() {
      if (!this.item) return []
      return Damage.CalculateDamage(this.item, this.mech)
    },
  },
  watch: {
    tab(newval: number) {
      this.item.SetProfileSelection(newval, true)
    },
  },
})

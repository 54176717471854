
























import Vue from 'vue'
import ActiveWeaponCard from './_ActiveWeaponCard.vue'
import CbCard from '../mech_loadout/components/mount/_CbCard.vue'
import ShLockCard from '../mech_loadout/components/mount/_ShLockCard.vue'

export default Vue.extend({
  name: 'mount-block',
  components: { ActiveWeaponCard, CbCard, ShLockCard },
  props: {
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    mount: {
      type: Object,
      required: true,
    },
    mech: {
      type: Object,
      required: true,
    },
    rest: {
      type: Boolean,
    },
  },
})

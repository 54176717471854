
















































import Vue from 'vue'

export default Vue.extend({
  name: 'size-attribute',
  props: {
    stats: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    cols: {
      type: [String, Number],
      required: false,
      default: '',
    },
    editable: {
      type: Boolean,
    },
    selectable: {
      type: Boolean,
    },
  },
  data: () => ({
    allSizes: [0.5, 1, 2, 3, 4],
    model: 0,
    menu: false,
  }),
})

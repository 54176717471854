


















































































import Vue from 'vue'
import { DiceRoller } from '@/class'

export default Vue.extend({
  name: 'burn-dialog',
  props: {
    mech: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    roll: null,
    dialog: false,
  }),
  computed: {
    rollTooltip() {
      if (this.roll) return `${this.roll} + ${this.mech.Eng}`
      return ''
    },
  },
  methods: {
    rollCheck() {
      this.roll = DiceRoller.rollToHit(this.mech.Eng).total
    },
    show() {
      this.dialog = true
    },
    complete(success) {
      if (success) {
        this.mech.Pilot.State.ClearBurn()
        this.$emit('complete', null)
      } else {
        const preStruct = this.mech.CurrentStructure
        const preHP = this.mech.CurrentHP
        this.mech.Pilot.State.TakeBurn()
        this.$emit('complete', {
          hp: preHP - this.mech.CurrentHP,
          str: preStruct - this.mech.CurrentStructure,
        })
      }
      this.roll = null
      this.dialog = false
    },
  },
})
































import Vue from 'vue'
import PilotAttack from '../../components/_PilotAttack.vue'

export default Vue.extend({
  name: 'selected-fight-dialog',
  components: { PilotAttack },
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },
    pilot: {
      type: Object,
      required: true,
    },
    overwatch: { type: Boolean },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    reset() {
      this.$refs.main.reset()
    },
    confirm(): void {
      this.dialog = false
      this.$emit('close')
      this.$refs.main.init()
    },
    show(): void {
      this.dialog = true
    },
    hide(): void {
      this.dialog = false
      this.$emit('close')
      this.$refs.main.init()
    },
  },
})














































































































































import Vue from 'vue'

import StatblockDialog from '../../../components/StatblockDialog.vue'
import PrintDialog from '../../../components/PrintDialog.vue'

export default Vue.extend({
  name: 'mech-nav',
  components: { StatblockDialog, PrintDialog },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
    selected: {
      type: Number,
      required: true,
    },
    mechID: {
      type: String,
      required: false,
    },
  },
  methods: {
    toTacticalProfile() {
      this.$router.push({ name: 'tactical_profile' })
    },
    toActive() {
      this.$router.push(`/active/${this.pilot.ID}`)
    },
  },
})

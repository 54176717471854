
























































import Vue from 'vue'
import ReserveSelector from './_ReserveSelector.vue'
import { Reserve } from '@/class'

export default Vue.extend({
  name: 'power-at-cost',
  components: { ReserveSelector },
  props: {
    pilot: { type: Object, required: true },
  },
  data: () => ({
    complication1: 'Нет',
    complication2: 'Нет',
    complications: [
      'Нет',
      'Это займет гораздо больше времени, чем вы думали',
      'Это будет чертовски рискованно',
      'Вам придется от чего-то отказаться или что-то оставить (например, богатство, ресурсы, союзников)',
      'Вы разозлите кого-то или что-то важное и влиятельное',
      'Все пойдет не по плану',
      'Вам понадобится больше информации, чтобы действовать безопасно',
      'Все развалится чертовски быстро',
      'Вам понадобится больше ресурсов, но вы знаете, где их найти',
      'Вы можете получить что-то почти правильное: меньшую версию или меньшее количество',
    ],
  }),
  methods: {
    addReserve() {
      const rs = this.$refs.rs
      const r = rs.reserveByID(rs.reserve)
      const nr = new Reserve({
        id: rs.reserve || 'reserve_custom',
        type: rs.type,
        name: (r && r.name) || rs.custom_name || 'Пользовательский резерв',
        label: rs.custom_name,
        description: (r && r.description) || '',
        resource_name: rs.custom_name || '',
        resource_note: rs.details || '',
        resource_cost: this.complication1,
        consumable: true,
        used: false,
      })
      if (this.complication2 !== 'Нет') nr.ResourceCost += `\n${this.complication2}`
      this.pilot.ReservesController.AddReserve(nr)
      this.close()
    },
    close() {
      this.complication1 = 'Нет'
      this.complication2 = 'Нет'
      this.$refs.rs.reset()
      this.$emit('close')
    },
  },
})

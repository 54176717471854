






























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ name: 'cc-tooltip' })
export default class CCTooltip extends Vue {
  @Prop({ type: String, required: false, default: '' })
  readonly err: string

  @Prop({ type: Boolean, required: false })
  readonly simple?: boolean

  @Prop({ type: Boolean, required: false })
  readonly top?: boolean

  @Prop({ type: Boolean, required: false })
  readonly bottom?: boolean

  @Prop({ type: Boolean, required: false })
  readonly left?: boolean

  @Prop({ type: Boolean, required: false })
  readonly right?: boolean

  @Prop({ type: Boolean, required: false })
  readonly inline?: boolean

  @Prop({ type: Boolean, required: false })
  readonly delayed?: boolean

  @Prop({ type: String, required: false, default: '' })
  readonly title: string

  @Prop({ type: String, required: true })
  readonly content!: string

  get isTop(): boolean {
    if (this.top) return true
    return !this.bottom && !this.left && !this.right
  }
}


































































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

export default Vue.extend({
  name: 'licenses',
  computed: {
    licenses() {
      const licenseData = getModule(CompendiumStore, this.$store).Licenses.filter(x => !x.Hidden)
      return this.$_.groupBy(licenseData, 'Source')
    },
  },
  methods: {
    manufacturer(id: string) {
      const compendium = getModule(CompendiumStore, this.$store)
      return compendium.Manufacturers.find(x => x.ID === id)
    },
    frame(id: string) {
      const compendium = getModule(CompendiumStore, this.$store)
      return compendium.referenceByID('Frames', id)
    },
  },
})

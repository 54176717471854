


























































































































































import Vue from 'vue'
import SyncItemMenu from './SyncItemMenu.vue'
import { GetItemsList, SyncLCPs, DeleteLCP, DeleteAllLocal, DeleteAllCloud } from '@/cloud/lcp_sync'

export default Vue.extend({
  name: 'sync-manager',
  components: { SyncItemMenu },
  data: () => ({
    panel: 0,
    loading: false,
    items: [],
    cloudItemTypes: ['Active Mission', 'Mission', 'Encounter', 'NPC', 'Pilot'],
  }),
  async mounted() {
    this.fetch()
  },
  computed: {
    selectedItems() {
      return this.items.filter(x => x.selected)
    },
  },
  methods: {
    selectAll() {
      this.items.forEach(i => {
        this.$set(i, 'selected', true)
      })
    },
    deselectAll() {
      this.items.forEach(i => {
        this.$set(i, 'selected', false)
      })
    },
    itemsByType(type) {
      return this.items.filter(x => x.itemType === type)
    },
    isAtLatest(item) {
      return item.localVersion === item.cloudVersion
    },
    async fetch() {
      this.loading = true
      this.items = await GetItemsList()
      this.loading = false
    },
    syncAll(hideAlert) {
      this.loading = true
      SyncLCPs()
        .then(() => this.fetch())
        .then(() => {
          if (!hideAlert) this.$notify(`Синхронизировано ${this.items.length} объектов успешно`, 'success')
        })
        .catch(() => {
          if (!hideAlert) this.$notify('При синхронизации произошла ошибка.', 'error')
        })
    },
    deleteItem(item) {
      this.loading = true
      DeleteLCP(item)
        .then(() => this.fetch())
        .then(() => this.$notify(`Удаление успешно`, 'success'))
        .catch(() => this.$notify('При удалении произошла ошибка.', 'error'))
    },
    deleteAllLocal() {
      DeleteAllLocal()
        .then(() => this.fetch())
        .then(() => this.$notify(`Удаление успешно`, 'success'))
        .catch(() => this.$notify('При удалении произошла ошибка.', 'error'))
    },
    deleteAllCloud() {
      DeleteAllCloud()
        .then(() => this.fetch())
        .then(() => this.$notify(`Удаление успешно`, 'success'))
        .catch(() => this.$notify('При удалении произошла ошибка.', 'error'))
    },
  },
})




































































































import Vue from 'vue'
import OverviewPage from './pages/OverviewPage.vue'
import SkillsPage from './pages/SkillsPage.vue'
import TalentsPage from './pages/TalentsPage.vue'
import MechSkillsPage from './pages/MechSkillsPage.vue'
import LicensePage from './pages/LicensePage.vue'
import CoreBonusPage from './pages/CoreBonusPage.vue'
import ConfirmPage from './pages/ConfirmPage.vue'
import { getModule } from 'vuex-module-decorators'
import { PilotManagementStore } from '@/store'
import { Pilot } from '@/class'

export default Vue.extend({
  name: 'level-wizard',
  components: {
    OverviewPage,
    SkillsPage,
    TalentsPage,
    MechSkillsPage,
    LicensePage,
    CoreBonusPage,
    ConfirmPage,
  },
  data: () => ({
    step: 1,
    pilot: {},
    cbEligible: false,
  }),
  computed: {
    currentPilot(): Pilot {
      return getModule(PilotManagementStore, this.$store).Pilots.find(
        p => p.ID === this.$route.params.pilotID
      )
    },
  },
  watch: {
    step() {
      window.scrollTo(0, 0)
    },
  },
  created() {
    this.pilot = Pilot.Deserialize(Pilot.Serialize(this.currentPilot))
    this.pilot.Level++
    this.cbEligible = this.pilot.CoreBonusController.IsMissingCBs
  },
})

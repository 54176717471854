















































































































import { Action } from '@/classes/Action'
import Vue from 'vue'

export default Vue.extend({
  name: 'deployable-info-base',
  props: {
    deployable: {
      type: Object,
      required: true,
    },
  },
  computed: {
    actions() {
      return this.deployable.actions ? this.deployable.actions.map(x => new Action(x)) : []
    },
  },
})

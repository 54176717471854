























import Vue from 'vue'

import HomeCard from '../components/HomeCard.vue'

export default Vue.extend({
  name: 'mission-landing',
  components: { HomeCard },
})











































































































































import Vue from 'vue'
import SimpleAttribute from './SimpleAttribute.vue'

export default Vue.extend({
  name: 'npc-panel',
  components: { SimpleAttribute },
  props: {
    npc: {
      type: Object,
      required: true,
    },
    sideSelect: {
      type: Boolean,
    },
  },
})





















































import Vue from 'vue'
export default Vue.extend({
  name: 'destroyed-alert',
  props: {
    mech: {
      type: Object,
      required: true,
    },
  },
  methods: {
    restore(restoreReactor: boolean): void {
      this.mech.Pilot.State.CancelSelfDestruct()
      this.$emit('restore', restoreReactor)
    }
  }
})












import Vue from 'vue'
export default Vue.extend({
  name: 'no-data-block',
})



















































































































import Vue from 'vue'
import PanelView from '../components/PanelView.vue'
import EncounterGroup from './components/EncounterGroup.vue'
import EncounterCard from './components/EncounterCard.vue'
import { getModule } from 'vuex-module-decorators'
import { EncounterStore } from '@/store'
import { Encounter } from '@/class'

export default Vue.extend({
  name: 'encounter-manager',
  components: { PanelView, EncounterCard, EncounterGroup },
  data: () => ({
    search: '',
    selectedEncounter: null,
    grouping: null,
    headers: [{ text: 'Name', value: 'Name', align: 'left' }],
    encounters: [],
  }),
  watch: {
    selectedEncounter() {
      this.$refs.view.resetScroll()
    },
  },
  created() {
    const store = getModule(EncounterStore, this.$store)
    this.encounters = store.Encounters
  },
  methods: {
    delete_encounter(encounter: Encounter) {
      encounter.SaveController.delete()
    },
    copyEncounter(encounter: Encounter) {
      const store = getModule(EncounterStore, this.$store)
      store.cloneEncounter(encounter)
    },
    addNew() {
      const store = getModule(EncounterStore, this.$store)
      store.addEncounter(new Encounter())
      const enc = this.encounters[this.encounters.length - 1].ID
      this.$router.push({ name: 'encounter', params: { id: enc } })
    },
  },
})

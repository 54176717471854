























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore, PilotManagementStore, NpcStore } from '@/store'

import { ContentPack } from '@/class'

@Component
export default class PacksList extends Vue {
  private expanded
  private compendiumStore = getModule(CompendiumStore, this.$store)

  public async toggleActive(packID: string, active: boolean): Promise<void> {
    await this.compendiumStore.setPackActive({
      packID,
      active,
    })
    await this.reload()
  }

  public async deletePack(id: string): Promise<void> {
    await this.compendiumStore.deleteContentPack(id)
    await this.reload()
  }

  public get contentPacks(): ContentPack[] {
    return this.compendiumStore.ContentPacks
  }

  public async reload() {
    this.$emit('start-load')
    const pilotStore = getModule(PilotManagementStore, this.$store)
    const npcStore = getModule(NpcStore, this.$store)
    const missing = { pilots: [], npcs: [] }
    await pilotStore.loadPilots()
    missing.pilots = pilotStore.MissingPilots
    await npcStore.loadNpcs()
    missing.npcs = npcStore.MissingNpcs
    await this.compendiumStore.setMissingContent(missing)
    this.$emit('end-load')
  }

  public headers = [
    { text: 'Активирован', value: 'toggleActive', sortable: false },
    { text: 'Название', value: 'Name' },
    { text: 'Автор', value: 'Author' },
    { text: 'Версия', value: 'Version' },
    { text: '', value: 'deleteAction', sortable: false },
  ]
}

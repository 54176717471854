



























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import EquipmentCardBase from './_EquipmentCardBase.vue'
import { WeaponMod } from '@/class'

@Component({ components: { EquipmentCardBase } })
export default class WeaponModCard extends Vue {
  @Prop({ type: Object, required: true })
  readonly item: WeaponMod
}

































































import Vue from 'vue'
import SlideItem from '../components/SlideItem.vue'
import PlayerCard from '../components/PlayerCard.vue'
import EncounterNav from '../components/EncounterNav.vue'
import { Pilot } from '@/class'

export default Vue.extend({
  name: 'rest-view',
  components: { SlideItem, PlayerCard, EncounterNav },
  props: {
    activeMission: {
      type: Object,
      required: true,
    },
    rest: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedPilot: null,
  }),
  computed: {
    pilots(): Pilot[] {
      return this.activeMission.Pilots.map(x => x.ActiveMech)
    },
    selected(): Pilot {
      return this.pilots.find(x => x.ID === this.selectedPilot)
    },
  },
  watch: {
    selectedPilot() {
      document.getElementById('scroll').scrollTop = 0
    },
  },
})

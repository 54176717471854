
























import ActiveCombat from './views/ActiveCombat.vue'
import ActiveNarrative from './views/ActiveNarrative.vue'
import ActiveRest from './views/ActiveRest.vue'
import MechBlock from './layout/MechBlock.vue'
import TurnFooter from './layout/TurnFooter.vue'

import activePilot from '@/features/pilot_management/mixins/activePilot'
import vueMixins from '@/util/vueMixins'

export default vueMixins(activePilot).extend({
  name: 'active-sheet',
  components: {
    MechBlock,
    TurnFooter,
    ActiveCombat,
    ActiveNarrative,
    ActiveRest,
  },
  data: () => ({
    showNotes: false,
  }),
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"hide-default-footer":"","disable-pagination":"","no-data-text":"Нет доступных пакетов контента.","headers":_vm.tableHeaders,"items":_vm.items,"show-expand":"","item-key":"name","loading":_vm.loading,"loading-text":"Загружаю данные пакета контента..."},scopedSlots:_vm._u([{key:"item.website",fn:function(ref){
var item = ref.item;
return [_c('cc-tooltip',{attrs:{"content":"Скачать"}},[_c('v-btn',{attrs:{"target":"_blank","href":item.link,"fab":"","small":"","color":"secondary"}},[_c('v-icon',{attrs:{"color":"anti"}},[_vm._v("open_in_new")])],1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.version",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"packVersion"},[_vm._v(" "+_vm._s(item.version)+" "),(_vm.packInstalled(item))?_c('span',[(_vm.packOutdated(item))?_c('cc-tooltip',{attrs:{"inline":"","title":"Пакет устарел","content":"Этот пакет содержимого установлен, но устарел и может вызвать ошибки в последней версии COMP/CON. Нажмите кнопку Скачать этого пакета, чтобы получить последнюю версию."}},[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-alert")])],1):_c('cc-tooltip',{attrs:{"inline":"","content":"Этот пакет контента установлен и обновлен."}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])],1)],1):_vm._e()])]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"cost"},[_vm._v(" "+_vm._s(item.cost)+" ")])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{staticClass:"py-4 px-6 w-100 light-panel",attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"body-text text--text pa-2 mb-1"},[(item.description)?_c('span',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(item.description),expression:"item.description"}]}):_c('span',[_vm._v(" Нет описания. ")])]),(item.website)?_c('div',{staticClass:"mt-2"},[_c('v-divider',{staticClass:"ma-1"}),_c('v-btn',{attrs:{"target":"_blank","href":item.website,"text":"","color":"secondary"}},[_c('v-icon',{attrs:{"prepend":"","left":""}},[_vm._v("open_in_new")]),_vm._v(" Сайт автора ")],1)],1):_vm._e()]),(item.img)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-img',{attrs:{"src":item.img,"alt":"Pack image"}})],1):_vm._e()],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }






































import Vue from 'vue'
import SignIn from './SignIn.vue'
import PasswordReset from './PasswordReset.vue'
import SignUp from './SignUp.vue'
import Verify from './Verify.vue'
import SignedIn from './SignedIn.vue'
import { Auth } from '@aws-amplify/auth'
import { UserStore } from '@/store'
import { getModule } from 'vuex-module-decorators'

export default Vue.extend({
  name: 'login-auth',
  components: { SignIn, PasswordReset, SignUp, Verify, SignedIn },
  data: () => ({
    state: 'sign-in',
    email: null,
    currentAuthedUser: null,
    oauthCode: null,
  }),
  async mounted() {
    await this.getAuthedUser()
    const userstore = getModule(UserStore, this.$store)
    if (userstore.IsPatron) {
      this.state = 'sign-up'
    }
  },
  methods: {
    verifyFlow(email) {
      this.email = email
      this.state = 'verify'
    },
    async getAuthedUser() {
      Auth.currentAuthenticatedUser()
        .then(user => {
          this.currentAuthedUser = user
          this.state = 'signed-in'
        })
        .catch(() => {
          this.currentAuthedUser = null
        })
    },
  },
})






























































































































































































































































































































































import Vue from 'vue'
import { OrgType, Organization } from '@/class'
export default Vue.extend({
  name: 'get-organized',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    tabs: 0,
    type: '',
    name: '',
    details: '',
    start: '',
    selected: null,
    improveRoll: '',
    badChoice: '',
    action: '',
    improvement: '',
  }),
  computed: {
    orgTypes() {
      return Object.keys(OrgType)
        .map(k => OrgType[k])
        .sort() as OrgType[]
    },
    organizations() {
      return this.pilot.ReservesController.Organizations
    },
    confirmDisabled() {
      if (this.tabs === 0) return !this.name || !this.type || !this.start
      if (this.tabs === 1) {
        if (parseInt(this.improveRoll) < 10) {
          return !(this.badChoice === 'fold' || (this.badChoice && this.action))
        } else if (parseInt(this.improveRoll) < 20) {
          if (this.selected.Efficiency === 6 && this.selected.Influence === 6) return false
          else return
          !this.improvement
        } else {
          return false
        }
      }
      return false
    },
  },
  methods: {
    confirmName() {
      if (this.tabs === 0) return 'Создать организацию'
      if (this.tabs === 1) {
        if (this.badChoice === 'fold') return 'Закрыть организацию'
        else return 'Обновить организацию'
      }
    },
    addOrg() {
      const org = new Organization({
        name: this.name,
        purpose: this.purpose,
        efficiency: this.start === 'efficiency' ? 2 : 0,
        influence: this.start === 'influence' ? 2 : 0,
        description: this.details,
        actions: '',
      })
      this.pilot.ReservesController.AddOrganization(org)
      this.close()
    },
    improveOrg() {
      if (parseInt(this.improveRoll) < 10) {
        if (this.badChoice === 'fold') {
          this.pilot.ReservesController.Organizations.splice(
            this.pilot.ReservesController.Organizations.findIndex(
              x => x.Name === this.selected.Name
            ),
            1
          )
        } else if (this.badChoice === 'efficiencyInfluence') {
          if(this.selected.Efficiency > 0)
            this.selected.Efficiency -= 2
          if(this.selected.Influence > 0)
            this.selected.Influence -= 2
          this.selected.Actions = this.action
          }
      } else if (parseInt(this.improveRoll) < 20) {
        if (this.improvement === 'efficiency') {
          this.selected.Efficiency += 2
        } else if (this.improvement === 'influence') {
          this.selected.Influence += 2
        }
      } else {
        this.selected.Efficiency += 2
        this.selected.Influence += 2
      }

      this.close()
    },
    close() {
      this.tabs = 0
      this.type = ''
      this.name = ''
      this.details = ''
      this.start = ''
      this.selected = null
      this.improveRoll = ''
      this.badChoice = ''
      this.action = ''
      this.improvement = ''
      this.$emit('close')
    },
  },
})







































import Vue from 'vue'
import FrameCardContent from './_FrameCardContent.vue'
import WeaponCardContent from './_WeaponCardContent.vue'
import GearCardContent from './_GearCardContent.vue'
import SystemCardContent from './_SystemCardContent.vue'

export default Vue.extend({
  name: 'compendium-large-card',
  components: { FrameCardContent, WeaponCardContent, GearCardContent, SystemCardContent },
  props: {
    item: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
    },
  },
})

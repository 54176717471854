


















import { ActivationType } from '@/classes/enums'
import Vue from 'vue'
export default Vue.extend({
  name: 'action-titlebar',
  props: {
    used: { type: Boolean },
    noAction: { type: Boolean },
    pilot: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  computed: {
    state() {
      return this.pilot.State
    },
    cost() {
      if (this.action.Activation === ActivationType.Full) return 2
      if (this.action.Activation === ActivationType.Quick) return 1
      return 0
    },
    unusable() {
      if (this.action.Activation === ActivationType.Protocol)
        return this.used || !this.pilot.State.IsProtocolAvailable
      return this.used || this.state.Actions < this.cost
    },
  },
})


































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Tag } from '@/class'

@Component({ name: 'cc-tags' })
export default class CCTags extends Vue {
  @Prop({ type: Boolean, required: false })
  readonly small?: boolean
  @Prop({ type: Boolean, required: false })
  readonly dense?: boolean
  @Prop({ type: Boolean, required: false })
  readonly outlined?: boolean

  @Prop({ type: Boolean, required: false })
  readonly extended?: boolean
  @Prop({ type: Boolean, required: false })
  readonly print?: boolean
  @Prop({ type: String, required: false, default: 'primary' })
  readonly color: string

  @Prop({ type: Array, required: true })
  readonly tags!: Tag[]

  @Prop({ type: Number, required: false, default: 0 })
  readonly bonus?: number
}


























































































































import { Vue, Component } from 'vue-property-decorator'

@Component({
  name: 'ref-stress-table',
})
export default class CCRefStressTable extends Vue {
  dialog = false
  show() {
    this.dialog = true
  }
  close() {
    this.dialog = false
  }
}





















import Vue from 'vue'
export default Vue.extend({
  name: 'section-edit-chip',
  props: {
    highlight: {
      type: Boolean,
      required: true,
    },
    current: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
})





















































import Vue from 'vue'
import SlotCardBase from '../_SlotCardBase.vue'
import EquipmentOptions from '../_EquipmentOptions.vue'

export default Vue.extend({
  name: 'mod-equipped-card',
  components: {
    SlotCardBase,
    EquipmentOptions,
  },
  props: {
    mech: {
      type: Object,
      required: true,
    },
    weapon: {
      type: Object,
      required: false,
      default: null,
    },
    mod: {
      type: Object,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    readonly: { type: Boolean },
  },
  data: () => ({
    hide: false,
  }),
})

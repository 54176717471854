




















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import _, { Dictionary } from 'lodash'
import { CoreBonus } from '@/class'
import { Manufacturer } from '@/classes/Manufacturer'

@Component
export default class CoreBonuses extends Vue {
  private compendium = getModule(CompendiumStore, this.$store)
  private manFilter = ''

  get bonuses(): Dictionary<CoreBonus[]> {
    let bArr = this.compendium.CoreBonuses.filter(x => !x.IsHidden)
    if (this.manFilter && this.manFilter.toLowerCase() !== 'все') {
      bArr = bArr.filter(x => x.Source === this.manFilter)
    }

    return _.groupBy(bArr, 'Source')
  }

  get sources(): string[] {
    return this.compendium.CoreBonuses.filter(x => !x.IsHidden).map(x => x.Source)
  }

  private mounted(): void {
    this.manFilter = 'ВСЕ'
  }

  public manufacturer(id: string): Manufacturer {
    const compendium = getModule(CompendiumStore, this.$store)
    return compendium.Manufacturers.find(x => x.ID === id.toUpperCase())
  }
}






























































































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { MechSystem } from '@/class'
import { flavorID } from '@/io/Generators'
import { Bonus } from '@/classes/components/feature/bonus/Bonus'

export default Vue.extend({
  name: 'mod-selector',
  props: {
    weapon: {
      type: Object,
      required: true,
    },
    mech: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    headers: [
      { text: 'Источник', align: 'left', value: 'Source' },
      { text: 'Мод', align: 'left', value: 'Name' },
      { text: 'Лицензия', align: 'left', value: 'LicenseString' },
      { text: 'СП', align: 'left', value: 'SP' },
      { text: '', align: 'center', value: 'Equip' },
    ],
    mods: [],
    showUnlicensed: false,
    showOverSP: false,
  }),
  computed: {
    freeSP(): number {
      return this.weapon.Mod ? this.mech.FreeSP + this.weapon.Mod.SP : this.mech.FreeSP
    },
    availableMods(): MechSystem[] {
      let i = this.mods.filter(x => !x.IsHidden)

      // filter by applied_to
      i = i.filter(x => x.AllowedTypes && x.AllowedTypes.includes(this.weapon.WeaponType))
      i = i.filter(x => x.AllowedSizes && x.AllowedSizes.includes(this.weapon.Size))

      // // filter out any mount restrictions
      i = i.filter(x => !x.RestrictedTypes || !x.RestrictedTypes.includes(this.weapon.WeaponType))
      i = i.filter(x => !x.RestrictedSizes || !x.RestrictedSizes.includes(this.weapon.Size))

      // filter already equipped
      if (this.weapon.Mod) i = i.filter(x => x.ID !== this.weapon.Mod.ID)

      // filter unique
      i = i.filter(
        x => !this.mech.MechLoadoutController.ActiveLoadout.UniqueMods.map(y => y.ID).includes(x.ID)
      )

      // filter ai
      if (
        this.mech.MechLoadoutController.ActiveLoadout.AICount >=
        1 + Bonus.get('ai_cap', this.mech)
      ) {
        i = i.filter(x => !x.IsAI)
      }

      if (!this.showUnlicensed) {
        i = i.filter(
          x => !x.LicenseLevel || this.mech.Pilot.has('License', x.License, x.LicenseLevel)
        )
      }

      // if (!this.showOverSP) {
      //   i = i.filter(x => x.SP <= this.freeSP)
      // }

      i = i.concat(this.mech.Pilot.SpecialEquipment.filter(x => x.ItemType === 'WeaponMod'))

      return i
    },
  },
  created() {
    const compendium = getModule(CompendiumStore, this.$store)
    this.mods = compendium.WeaponMods.filter(x => x.Source)
  },
  methods: {
    fID(template: string): string {
      return flavorID(template)
    },
  },
})







































import Vue from 'vue'

export default Vue.extend({
  name: 'cc-filter-panel',

  props: {
    itemType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    filterCount: 0,
    panel: false,
  }),
  methods: {
    clearFilters() {
      this.$refs.controls.clear()
      this.applyFilters({})
    },
    applyFilters(newFilters) {
      this.filterCount = Object.keys(newFilters).length
      this.$emit('set-filters', newFilters)
    },
  },
})

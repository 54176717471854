





























































































import Vue from 'vue'
import { Project } from '@/class'

export default Vue.extend({
  name: 'custom-reserve-panel',
  data: () => ({
    projectName: '',
    details: '',
    complicated: false,
    finished: false,
    costs: [],
    projectCosts: [
      'Качественные материалы',
      'Специальные знания или методы',
      'Специализированные инструменты',
      'Хорошее рабочее место',
    ],
  }),
  methods: {
    add() {
      const p = new Project({
        id: 'reserve_project',
        type: 'Project',
        name: `${this.projectName} ${this.finished ? '' : ' (In Progress)'}`,
        label: 'Project',
        description: '',
        complicated: this.complicated,
        can_finish: false,
        finished: false,
        progress: 0,
        requirements: [],
        resource_name: this.projectName,
        resource_note: this.details,
        resource_cost: '',
        used: false,
        consumable: false,
      })
      if (this.costs && !this.finished) p.ResourceCost = `Requires: ${this.costs.join(', ')}`
      p.IsFinished = this.finished
      this.clear()
      this.$emit('add', p)
    },
    clear() {
      this.projectName = ''
      this.details = ''
      this.complicated = false
      this.finished = false
      this.costs = []
    },
  },
})

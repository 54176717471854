






































































import Vue from 'vue'
import { FrameGalleryModal, FrameStatblock, FrameCoreSystemPanel } from './frame'
import { glossary } from 'lancer-data-rus'

export default Vue.extend({
  name: 'cc-frame-card',
  components: { FrameGalleryModal, FrameStatblock, FrameCoreSystemPanel },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    glossary(name: string) {
      return glossary.find(x => x.name.toLowerCase() === name.toLowerCase()).description
    },

    get_mount_tooltip(mount_type: string) {
      const mount_tooltips = {
        "Тяжелый": "Вмещает одно <b>Тяжёлое</b>, <b>Основное</b> либо <b>Вторичное</b> оружие",
        "Основной": "Вмещает одно <b>Основное</b> либо <b>Вторичное</b> оружие",
        "Втор./Вспом.": "Вмещает до 2 <b>Вторичных</b> оружий",
        "Втор.": "Вмещает одно <b>Вторичное</b> оружие",
        "Осн./Втор.": "Вмещает одно <b>Основное</b> и одно <b>Вторичное</b>, либо два <b>Вторичных</b> оружия",
        "Гибкий": "Вмещает одно <b>Основное</b> либо два <b>Вторичных</b> оружия",
      }
      if (mount_type in mount_tooltips) {
        return mount_tooltips[mount_type]
      }
      return "Ошибка: Неизвестный тип слота"
    },
  },
})

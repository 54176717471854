



















import Vue from 'vue'
export default Vue.extend({
  name: 'cc-string-edit',
  props: {
    dark: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    menu: false,
  }),
  methods: {
    save() {
      this.$emit('save')
      this.menu = false
    },
  },
})
























import Vue from 'vue'
import deployableInfoBase from './_deployableInfoBase.vue'

export default Vue.extend({
  name: 'action-hover',
  components: { deployableInfoBase },
  props: {
    deployable: {
      type: Object,
      required: true,
    },
  },
})






























import Vue from "vue";
export default Vue.extend({
  name: "talent-emblem",
  props: {
    url: { type: String, required: true },
    name: { type: String, required: true },
    small: { type: Boolean },
    large: { type: Boolean },
    white: { type: Boolean },
  },
  data: () => ({
    backup: "",
    src: "",
  }),
  computed: {
    size() {
      if (this.large) return "100px";
      if (this.small) return "50px";
      return "45px";
    },
  },
  mounted() {
    this.src = this.url;
  },
  methods: {
    imageLoadFailed() {
      this.src = "/static/img/talent/GENERIC TALENT.svg";
      this.backup = this.name;
    },
  },
});

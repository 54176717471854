





















import Vue from 'vue'
import BlankPilotPrint from './BlankPilotPrint.vue'
import PilotPrint from './PilotPrint.vue'
import BlankBondsPrint from './BlankBondsPrint.vue'
import BondsPrint from './BondsPrint.vue'
import BlankMechPrint from './BlankMechPrint.vue'
import MechPrint from './MechPrint.vue'
import PrintFooter from './PrintFooter.vue'
import { getModule } from 'vuex-module-decorators'
import { PilotManagementStore, CompendiumStore } from '@/store'
import { Pilot } from '@/class'

export default Vue.extend({
  name: 'combined-print',
  components: {
    BlankPilotPrint,
    PilotPrint,
    BlankBondsPrint,
    BondsPrint,
    BlankMechPrint,
    MechPrint,
    PrintFooter,
  },
  props: {
    pilotID: {
      type: String,
      required: true,
    },
    mechID: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    pilot: null,
    mech: null,
    blank: false,
  }),
  created() {
    if (this.pilotID === 'blank') this.blank = true
    this.pilot = getModule(PilotManagementStore, this.$store).Pilots.find(
      p => p.ID === this.pilotID
    )
    this.mech =
      !this.mechID || this.mechID === 'blank'
        ? null
        : (this.pilot as Pilot).Mechs.find(m => m.ID === this.mechID)
  },
  computed: {
    hasBondData() {
      return getModule(CompendiumStore, this.$store).Bonds.length
    },
  },
})
















import Vue from 'vue'
import CCardBase from './_cCardBase.vue'
export default Vue.extend({
  name: 'weapon-card-content',
  components: { CCardBase },
  props: {
    item: { type: Object, required: true },
    small: { type: Boolean },
    hover: { type: Boolean },
  },
})




















import Vue from 'vue'
export default Vue.extend({
  name: 'cc-confirmation',
  props: {
    content: {
      type: String,
      required: true,
    },
    noCc: { type: Boolean },
  },
})














































import Vue from 'vue'
import { EquippableMount } from '@/class'

export default Vue.extend({
  name: 'sh-lock-dialog',
  props: {
    mech: {
      type: Object,
      required: true,
    },
    mount: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    availableMounts: [],
  }),
  mounted() {
    const candidates = this.mech.MechLoadoutController.ActiveLoadout.AllEquippableMounts(
      this.mech.Pilot.has('corebonus', 'cb_improved_armament'),
      false
    ) as EquippableMount[]

    this.availableMounts = candidates.filter(x => x.Name !== this.mount.Name)
  },
  methods: {
    show() {
      this.dialog = true
    },
    hide() {
      this.dialog = false
    },
  },
})

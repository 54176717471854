







































import Vue from 'vue'

export default Vue.extend({
  name: 'license-page',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
})





































import Vue from 'vue'
export default Vue.extend({
  name: 'dev-badge',
  props: {
    info: {
      type: Object,
      required: true,
    },
    cols: { type: Number, default: 6 },
  },
})










































































import Vue from 'vue'

export default Vue.extend({
  name: 'cc-loadout-panel',
  props: {
    loadouts: {
      type: Array,
      required: true,
    },
    activeLoadout: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    readonly: {
      type: Boolean,
    },
  },
  data: () => ({
    confirmMenu: false,
  }),
  methods: {
    removeConfirm() {
      this.confirmMenu = false
      this.$emit('remove-loadout')
    },
  },
})

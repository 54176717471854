











































import Vue from 'vue'
export default Vue.extend({
  name: 'mission-step-element',
  props: {
    step: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'Primary',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    index: {
      type: Number,
      required: true,
    },
    missionLength: {
      type: Number,
      required: true,
    },
  },
})

































































































import Vue from 'vue'
import { Auth } from '@aws-amplify/auth'
// import { loginUrl } from '@/cloud/patreon'
import { UserStore } from '@/store'
import { getModule } from 'vuex-module-decorators'
// import popupOauth from '@/cloud/oauth2-popup'

export default Vue.extend({
  name: 'sign-up',
  // components: { Auth },
  data: () => ({
    showError: false,
    error: '',
    loading: false,
    show: false,
    email: '',
    password: '',
    // patreonAuthCode: '',
    rules: {
      required: value => !!value || 'Необходио.',
      min: v => v.length >= 6 || 'Минимум 6 символов',
      emailMatch: v =>
        !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v) || 'Адрес должен быть действительным',
    },
  }),
  computed: {
    // isPatron() {
    //   return !!this.patreonAuthCode
    // },
    submitOk() {
      return (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(this.email) && this.password.length >= 6
      )
    },
    test() {
      return process.env.VUE_APP_SOMEKEY
    },
  },
  methods: {
    async createAccount() {
      if (this.loading) return // debounce if already loading
      this.loading = true
      try {
        const userEmail = this.email.toLowerCase() // use safe const for auth
        this.email = userEmail
        const { user } = await Auth.signUp({
          username: userEmail,
          password: this.password,
          attributes: {
            email: userEmail,
          },
        })
        this.loading = false
        this.showError = false
        this.$emit('success', userEmail)
        // const userstore = getModule(UserStore, this.$store)
        // userstore.clearOauth()
      } catch (error) {
        console.log('error signing up:', error)
        this.loading = false
        this.showError = true
        this.error = `${error.message}<br><div class='text-right'>${error.name}</div>`
      }
    },
    // async verifyPatreon() {
    //   const authorizationCode = await popupOauth(
    //     'https://www.patreon.com/oauth2/authorize',
    //     '_1O6Z4dBszp3Q9ERr93RVNCwM1VUveu9xI5vq1DqJUXEK47FC7MkTtF1lwT5_ko3',
    //     'https://compcon.app/patreon-callback',
    //     '',
    //     'code'
    //   )
    //   this.patreonAuthCode = authorizationCode
    // },
  },
})































import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'
import ItemSelectorRow from '../../components/_ItemSelectorRow.vue'
import WSkirmishDialog from './_SelSkirmishDialog.vue'

import Vue from 'vue'

export default Vue.extend({
  name: 'skirmish-dialog',
  components: { ActionDetailExpander, ItemSelectorRow, WSkirmishDialog },
  props: {
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selected: null,
    selectedMount: null,
  }),
  computed: {
    state() {
      return this.mech.Pilot.State
    },
  },
  methods: {
    disableSkirmish(w) {
      if (w.IsLoading && !w.Loaded) return true
      if (w.IsOrdnance && !this.state.IsProtocolAvailable) return true
      if (!w.CanSkirmish) return false
      return !this.state.IsSkirmishAvailable
    },
    skirmish(item, mount) {
      Vue.nextTick().then(() => {
        this.$refs.s_dialog.init()
      })
      Vue.nextTick().then(() => {
        this.selected = item
        this.selectedMount = mount
      })
      Vue.nextTick().then(() => {
        this.$refs.s_dialog.show()
      })
    },
    confirmSkirmish(free) {
      this.state.RegisterSkirmish(free)
    },
  },
})

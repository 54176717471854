



























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { MechWeapon, PilotWeapon } from '@/class'

@Component({ name: 'selector-table-view' })
export default class SelectorTableView extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  readonly headers: any[]

  get shownHeaders(): any[] {
    const hide = ['weapon', 'system', 'item', 'license level']
    return this.$vuetify.breakpoint.smAndDown
      ? this.headers.filter(x => !hide.includes(x.text.toLowerCase()))
      : this.headers
  }

  @Prop({
    type: Array,
    required: true,
  })
  readonly items: PilotWeapon[] | MechWeapon[]

  @Prop({ type: Boolean })
  readonly spDisable: boolean

  @Prop({ type: Boolean })
  readonly spIgnore: boolean

  @Prop({ type: Number, required: false, default: 0 })
  readonly sp: boolean

  tableHeight = 535
  mounted() {
    this.onResize()
  }

  customSort(items, index, descending) {
    const desc = descending[0]
    items.sort((a, b) => {
      if (index[0] === 'Damage[0].Max') {
        return desc ? b.MaxDamage - a.MaxDamage : a.MaxDamage - b.MaxDamage
      } else if (index[0] === 'Range[0].Max') {
        return desc ? b.Range[0].Max - a.Range[0].Max : a.Range[0].Max - b.Range[0].Max
      } else {
        return desc ? (a[index[0]] < b[index[0]] ? -1 : 1) : b[index[0]] < a[index[0]] ? -1 : 1
      }
    })
    return items
  }

  onResize() {
    this.tableHeight = window.innerHeight - 215
  }
}






























































































































































import Vue from 'vue'
export default Vue.extend({
  name: 'pl-pilot-card-base',
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: false,
      default: null,
    },
    extended: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
  },
  methods: {
    closeSelector() {
      this.$refs.selectorDialog.hide()
    },
    openDetail() {
      this.$refs.detailDialog.show()
    },
    save(prop, newName) {
      this.$set(this.item, prop, newName)
      this.$emit('save')
    },
  },
})

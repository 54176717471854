






































import Vue from 'vue'
import SectionEditChip from '../../components/SectionEditChip.vue'
import HasePips from './HasePips.vue'

export default Vue.extend({
  name: 'skill-block',
  components: { SectionEditChip, HasePips },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
})

























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ name: 'cc-sidebar-view' })
export default class CCSidebarView extends Vue {
  @Prop({ type: Boolean, required: false })
  popup?: boolean
}



















































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ name: 'compendium-table-view' })
export default class CompendiumTableView extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  readonly headers: any[]

  get shownHeaders(): any[] {
    const hide = ['weapon', 'system', 'item', 'license level']
    return this.$vuetify.breakpoint.smAndDown
      ? this.headers.filter(x => !hide.includes(x.text.toLowerCase()))
      : this.headers
  }

  @Prop({
    type: Array,
    required: true,
  })
  readonly items: any[]

  itemType = ''
  tableHeight = 500

  mounted(): void {
    this.onResize()
  }

  onResize(): void {
    this.tableHeight = window.innerHeight - 160
  }
}





























import Vue from 'vue'

export default Vue.extend({
  name: 'print-dialog',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedMechID: null,
  }),
  created() {
    if (this.pilot.ActiveMech) this.selectedMechID = this.pilot.ActiveMech.ID
  },
  methods: {
    show() {
      this.$refs.dialog.show()
    },
    hide() {
      this.$refs.dialog.hide()
    },
    print() {
      this.$router.push(`/print/${this.pilot.ID}/${this.selectedMechID}`)
    },
    printBlank() {
      this.$router.push(`/print/blank/blank`)
    },
  },
})













































































































import { Vue, Component } from 'vue-property-decorator'

@Component({
  name: 'ref-structure-table',
})
export default class CCRefStructureTable extends Vue {
  dialog = false
  show() {
    this.dialog = true
  }
  close() {
    this.dialog = false
  }
}

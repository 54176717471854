





















































































































import Vue from 'vue'
export default Vue.extend({
  name: 'active-mission-info',
  props: {
    mission: {
      type: Object,
      required: true,
    },
    encounter: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
})

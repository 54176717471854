









import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ name: 'cc-notification' })
export default class CCNotification extends Vue{

  @Prop({ type: String, required: false, default: 'primary', })
  readonly color: string
    
  model = false
  close() {
    this.model = false
  }
  open() {
    this.model = true
  }
}






































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

export default Vue.extend({
  name: 'bond-selector',
  computed: {
    bonds() {
      return getModule(CompendiumStore, this.$store).Bonds
    },
  },
})

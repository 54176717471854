
























































import { Action } from '@/classes/Action'
import Vue from 'vue'
import PrintAction from './PrintAction.vue'

export default Vue.extend({
  name: 'print-deployable',
  components: { PrintAction },
  props: {
    deployables: {
      type: Array,
      required: true,
    },
  },
  methods: {
    actions(deployable) {
      return deployable.actions ? deployable.actions.map(x => new Action(x)) : []
    },
  },
})

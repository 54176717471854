























import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'
import ItemSelectorRow from '../../components/_ItemSelectorRow.vue'
import SelFightDialog from './_SelFightDialog.vue'

import Vue from 'vue'
import { ActivationType } from '@/classes/enums'

export default Vue.extend({
  name: 'fight-dialog',
  components: { ActionDetailExpander, ItemSelectorRow, SelFightDialog },
  props: {
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    selected: null,
    selectedMount: null,
  }),
  computed: {
    state() {
      return this.mech.Pilot.State
    },
  },
  methods: {
    completeFight() {
      this.$emit('use')
      this.state.CommitAction(this.action, ActivationType.Full)
      this.dialog = false
    },
    fight(item) {
      this.selected = item
      this.$refs.f_dialog.show()
    },
  },
})

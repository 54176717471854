














































import { Vue, Component, Prop } from 'vue-property-decorator'
@Component({ name: 'cc-item-modal' })
export default class CCItemModal extends Vue {
  @Prop({ type: Object, required: true })
  readonly item
  @Prop({ type: Boolean })
  readonly smallBtn: boolean
  truncate(str): string {
    if (str.length > 26) return str.substring(0, 24) + '…'
    return str
  }
}





















































import Vue from 'vue'

export default Vue.extend({
  name: 'cb-item',
  props: {
    bonus: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    isSelectable: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    ttContent() {
      if (!this.isSelected && !this.isSelectable) return 'Locked'
      else if (this.isSelected) return `Убрать ${this.bonus.Name}`
      return `Добавить ${this.bonus.Name}`
    },
  },
})

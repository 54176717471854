



























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ name: 'cc-popup' })
export default class CCPopup extends Vue {
  @Prop({ type: Boolean, required: false, })
  readonly flat?: boolean
  @Prop({ type: Boolean, required: false, })
  readonly icon?: boolean

  dialog = false
  confirm() {
    this.dialog = false
    this.$emit('confirm')
  }
}

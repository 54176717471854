































































import Vue from 'vue'
import Component from 'vue-class-component'
import _ from 'lodash'

@Component({
  props: {
    pack: { type: Object, required: true },
  },
})
export default class PackInfo extends Vue {
  private humanReadableMap = {
    manufacturers: ['manufacturer', 'производителей'],
    coreBonuses: ['core bonus', 'бонусов ядра'],
    frames: ['frame', 'рам'],
    weapons: ['weapon', 'оружия'],
    systems: ['system', 'систем'],
    mods: ['weapon mod', 'модов оружия'],
    pilotGear: ['pilot gear item', 'снаряжения пилота'],
    backgrounds: ['background', 'предысторий'],
    bonds: ['bond', 'связей'],
    reserves: ['reserve', 'резервов'],
    talents: ['pilot talent', 'талантов пилота'],
    tags: ['equipment tag', 'меток снаряжения'],
    npcClasses: ['NPC class', 'классов НИП'],
    npcFeatures: ['NPC feature', 'систем НИП'],
    npcTemplates: ['NPC template', 'шаблонов НИП'],
    actions: ['Player action', 'действий игрока'],
    statuses: ['Status/Condition', 'Статусов/Состояний'],
    environments: ['Combat Environment', 'Боевых Окружений'],
    factions: ['faction', 'фракций'],
    sitreps: ['SITREP', 'СИТРЕПов'],
    tables: ['Data Table', 'таблиц данных'],
  }

  get packContents() {
    return _.toPairs(this.$props.pack.data)
      .map(([key, value]: [string, object[]]) => {
        const count = value.length
        return [key, count]
      })
      .filter(([, count]) => count > 0)
      .map(([key, count]) => {
        const pair = this.humanReadableMap[key]
        if (!pair) return { count, name: `${key}--NOT--HUMANIZED` }
        const [singular, plural]: [string, string] = pair
        return { count, name: count > 1 ? plural : singular }
      })
  }
}






























































































































































import Vue from 'vue'
import { Tag, WeaponType, WeaponSize, RangeType, DamageType, Manufacturer } from '@/class'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

const nameSort = function(a, b): number {
  if (a.text.toUpperCase() < b.text.toUpperCase()) return -1
  if (a.text.toUpperCase() > b.text.toUpperCase()) return 1
  return 0
}

export default Vue.extend({
  name: 'frame-filter',
  data: () => ({
    sourceFilter: [],
    tagFilter: [],
    weaponTypeFilter: [],
    weaponSizeFilter: [],
    attackTypeFilter: [],
    damageTypeFilter: [],
    sp: '',
    spType: '',
    lcpFilter: [],
  }),
  computed: {
    manufacturers(): Manufacturer[] {
      return this.$store.getters
        .getItemCollection('Manufacturers')
        .map(x => ({ text: x.Name, value: x.ID }))
        .sort(nameSort)
    },
    weaponTypes(): WeaponType[] {
      return Object.keys(WeaponType)
        .map(k => WeaponType[k as any])
        .filter(k => k !== 'Интегрированный')
        .sort() as WeaponType[]
    },
    weaponSizes(): WeaponSize[] {
      return Object.keys(WeaponSize)
        .map(k => WeaponSize[k as any])
        .sort() as WeaponSize[]
    },
    attackTypes(): RangeType[] {
      return Object.keys(RangeType)
        .map(k => RangeType[k as any])
        .sort() as RangeType[]
    },
    damageTypes(): DamageType[] {
      return Object.keys(DamageType)
        .map(k => DamageType[k as any])
        .sort() as DamageType[]
    },
    tags(): Tag[] {
      return this.$_.uniqBy(
        [].concat(
          this.$store.getters
            .getItemCollection('MechWeapons')
            .flatMap(x => x.Tags)
            .filter(x => !x.FilterIgnore && !x.IsHidden)
        ),
        'ID'
      )
    },
    lcps(): string[] {
      return getModule(CompendiumStore).Frames.map(x => x.LcpName)
    },
  },
  methods: {
    clear() {
      this.sourceFilter = []
      this.tagFilter = []
      this.weaponTypeFilter = []
      this.weaponSizeFilter = []
      this.attackTypeFilter = []
      this.damageTypeFilter = []
      this.sp = ''
      this.spType = ''
      this.lcpFilter = []
    },
    updateFilters() {
      const fObj = {} as any
      if (this.lcpFilter && this.lcpFilter.length) fObj.LcpName = [this.lcpFilter]
      if (this.spType && parseInt(this.sp) !== NaN) fObj[`SP_${this.spType}`] = parseInt(this.sp)
      if (this.sourceFilter && this.sourceFilter.length) fObj.Source = [this.sourceFilter]
      if (this.tagFilter && this.tagFilter.length) fObj.Tags = this.tagFilter
      if (this.weaponTypeFilter && this.weaponTypeFilter.length)
        fObj.WeaponType = [this.weaponTypeFilter]
      if (this.weaponSizeFilter && this.weaponSizeFilter.length) fObj.Size = [this.weaponSizeFilter]
      if (this.attackTypeFilter && this.attackTypeFilter.length)
        fObj.RangeType = this.attackTypeFilter
      if (this.damageTypeFilter && this.damageTypeFilter.length)
        fObj.DamageType = this.damageTypeFilter
      this.$emit('set-filters', fObj)
    },
  },
})

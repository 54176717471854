





















































import Vue from 'vue'
export default Vue.extend({
  name: 'talent-rank-contents',
  props: {
    talentRank: { type: Object, required: true },
    unlocked: { type: Boolean, default: true },
  },
})
































import Vue from 'vue'
import SidebarArrayView from '../components/SidebarArrayView.vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

export default Vue.extend({
  name: 'bonds',
  components: { SidebarArrayView },
  data: () => ({
    bonds: [],
  }),
  created() {
    this.bonds = getModule(CompendiumStore, this.$store).Bonds
  },
})

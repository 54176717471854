






















import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import GetColorMixin from '@/mixins/getColor'

@Component({ name: 'cc-combo-select', })
export default class CCComboSelect extends Mixins(GetColorMixin) {

  @Prop({ type: Array, required: true, })
  readonly items!: { 
    text: string | number | object,
    value: string | number | object,
  }[]
  
  sel: string | number | {value: any} = ''
  menu = false
  
  @Watch('sel')
  onSelChange() {
    const s = typeof this.sel === 'object' ? this.sel.value : this.sel
    this.$emit('set', s)
  }
}





















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import Selector from './components/_SelectorBase.vue'
import MissingItem from './components/_MissingItem.vue'
import CoreBonusSelectItem from './components/_CoreBonusSelectItem.vue'

import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { Pilot, CoreBonus, Manufacturer } from '@/class'
import { Bonus } from '@/classes/components/feature/bonus/Bonus'

@Component({
  components: { Selector, CoreBonusSelectItem, MissingItem },
})
export default class CCCoreBonusSelector extends Vue {
  @Prop({ type: Object, required: true }) pilot!: Pilot
  @Prop(Boolean) levelUp!: boolean

  get coreBonuses(): CoreBonus[] {
    return getModule(CompendiumStore, this.$store).CoreBonuses.filter(x => !x.IsHidden)
  }
  get manufacturersWithCBs(): {
    manufacturer: Manufacturer
    coreBonuses: CoreBonus[]
  }[] {
    const manufacturers = getModule(CompendiumStore, this.$store).Manufacturers

    return manufacturers
      .filter(x => !x.IsHidden)
      .map(manufacturer => ({
        manufacturer,
        coreBonuses: this.coreBonuses.filter(cb => cb.Manufacturer.ID === manufacturer.ID),
      }))
      .filter(x => x.coreBonuses.length > 0)
  }

  get selectionComplete(): boolean {
    return this.levelUp && !this.pilot.CoreBonusController.IsMissingCBs
  }

  @Watch('selectionComplete') onSelectionComplete(): void {
    window.scrollTo(0, document.body.scrollHeight)
  }

  requirement(m: Manufacturer): string {
    const br = this.$vuetify.breakpoint.smAndDown ? '<br>' : '&emsp;//&emsp;'
    const abbr = `<b>${m.ID}</b>`
    const name = `<b>${m.Name}</b>`
    if (m.ID === 'ДМС')
      return `<b>${this.selectedCount(
        m.ID
      )}</b> ${abbr} Бонусов Ядра выбрано<br>${name} Бонусы Ядра не имеют требований по лицензиям`
    const lvl = `<b>${this.pilot.LicenseController.LicenseLevel(m.ID)}</b>`
    let output = `${lvl} ${abbr} Лицензий Получено ${br} `
    let remain = (3 % this.pilot.Level || 3) - this.pilot.LicenseController.LicenseLevel(m.ID)
    if (remain < 1) remain += 3
    output += `<b>${this.availableCount(m.ID)}</b> ${abbr} Бонусов Ядра доступно ${br} `
    output += `<b>${this.selectedCount(m.ID)}</b> ${abbr} Бонусов Ядра выбрано`
    if (this.pilot.Level < 12)
      output += `<br>${
        this.pilot.Level < 3 ? 'Первый' : 'Следующий'
      } ${name} Бонус Ядра доступен через <b>${remain}</b> УЛ`
    return output
  }

  selectedCount(m: string): number {
    return this.pilot.CoreBonusController.CoreBonuses.filter((x: CoreBonus) => x.Source === m)
      .length
  }
  availableCount(m: string): number {
    if (m.toUpperCase() === 'ДМС') return Infinity
    const extraLicenses = Bonus.Int(0, 'cb_point', this.pilot)
    return (
      Math.floor(this.pilot.LicenseController.LicenseLevel(m) / 3) +
      extraLicenses -
      this.selectedCount(m)
    )
  }
  isSelectable(b: CoreBonus): boolean {
    return this.availableCount(b.Source) > 0 && this.pilot.CoreBonusController.IsMissingCBs
  }
  isSelected(b: CoreBonus): boolean {
    return this.pilot.has('CoreBonus', b.ID)
  }
}

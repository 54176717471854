









































































import Vue from 'vue'
import { generateCode, refreshItem } from '@/io/apis/share'

export default Vue.extend({
  name: 'share-dialog',
  props: {
    pilot: { type: Object, required: true },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    isSameDate() {
      return (
        Math.floor(
          (new Date().valueOf() - new Date(this.pilot.CloudController.ShareCodeExpiry).valueOf()) /
            (1000 * 60 * 60 * 24)
        ) < 1
      )
    },
    extendedDate() {
      const d = new Date()
      d.setDate(d.getDate() + 90)

      return d.toLocaleDateString(undefined, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
  },
  methods: {
    async generate() {
      this.loading = true
      await generateCode(this.pilot)
        .then(res => this.pilot.CloudController.SetShareCode(res))
        .then(() => this.$notify('Код пилота сгенерирован', 'success'))
        .catch(() => this.$notify('Возникла ошибка при попытке создания кода пилота', 'error'))
      this.loading = false
    },
    async refresh() {
      this.loading = true
      const c = this.pilot.CloudController.ShareCode
      await refreshItem(c)
        .then(() => this.pilot.CloudController.SetShareCode(c))
        .then(() => this.$notify('Код пилота обновлен', 'success'))
        .catch(() => this.$notify('Возникла ошибка при попытке обновления кода пилота', 'error'))
      this.loading = false
    },
    copy() {
      navigator.clipboard
        .writeText(this.pilot.CloudController.ShareCode)
        .then(() => Vue.prototype.$notify('Cloud ID copied to clipboard.', 'confirmation'))
        .catch(() => Vue.prototype.$notifyError('Unable to copy Cloud ID'))
    },
  },
})

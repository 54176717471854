


































import Vue from 'vue'

export default Vue.extend({
  name: 'editable-attribute',
  props: {
    attr: {
      type: String,
      required: true,
    },
    val: {
      type: [String, Number],
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    cols: {
      type: [String, Number],
      required: false,
      default: '',
    },
    editable: {
      type: Boolean,
    },
  },
  data: () => ({
    model: 0,
    editMode: false,
  }),
  watch: {
    val() {
      this.model = this.val
    },
  },
})











































































































































































































































































































import Vue from 'vue'
import BondSelector from './components/BondSelector.vue'
import BondPowerSelector from './components/BondPowerSelector.vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

export default Vue.extend({
  name: 'bonds-view',
  components: { BondSelector, BondPowerSelector },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    resetXpMenu: false,
    addBondMenu: false,
  }),
  computed: {
    bonds() {
      return getModule(CompendiumStore, this.$store).Bonds
    },
    hasBond() {
      return !!this.pilot.BondController.Bond
    },
    bid() {
      return this.pilot.BondController.Bond ? this.pilot.BondController.Bond.ID : 'none'
    },
    underlevel() {
      return this.pilot.Level < 1
    },
    boon() {
      return this.pilot.BondController.Bond.Boon
    },
  },
  methods: {
    async setBond(bond) {
      this.$set(this.pilot.BondController, 'Bond', bond)
      await this.$forceUpdate()
      this.$refs.choosebond.hide()
    },
  },
})

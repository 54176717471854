















import { Component, Prop, Mixins } from 'vue-property-decorator'
import GetColorMixin from '@/mixins/getColor'

@Component({ name: 'cc-title' })
export default class CCTitle extends Mixins(GetColorMixin) {
  @Prop({ type: Boolean, required: false })
  readonly large?: boolean

  @Prop({ type: Boolean, required: false })
  readonly small?: boolean

  @Prop({ type: Boolean, required: false, default: true })
  readonly dark: boolean

  @Prop({ type: String, required: false, default: 'primary' })
  readonly color: string

  get size() {
    if (this.$vuetify.breakpoint.smAndDown) return '-small'
    return this.large ? '-large' : this.small ? '-small' : ''
  }
}













































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { NpcFeature } from '@/class'

export default Vue.extend({
  name: 'npc-freature-selector',
  props: {
    npc: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    headers: [
      { text: 'Название', align: 'left', value: 'Name' },
      { text: 'Тип', align: 'left', value: 'FeatureType' },
      { text: 'Класс', align: 'left', value: 'OriginClass' },
      { text: 'Набор', align: 'left', value: 'OriginSet' },
      { text: '', align: 'center', value: 'Detail' },
    ],
    features: [],
    showAll: false,
    showDupe: false,
  }),
  computed: {
    availableFeatures(): NpcFeature[] {
      let i = []
      if (this.showAll) i = this.features
      else {
        const templateFeatures = this.npc.Templates.flatMap(x => x.OptionalFeatures)
        i = templateFeatures.concat(this.npc.Class.OptionalFeatures)
      }
      if (!this.showDupe) i = i.filter(x => !this.npc.SelectedFeatures.some(y => y.ID === x.ID))
      else {
        const tempBaseFeats = this.npc.Templates.flatMap(x => x.BaseFeatures)
        i = i.concat(this.npc.Class.BaseFeatures).concat(tempBaseFeats)
      }
      return i
    },
  },
  created() {
    const compendium = getModule(CompendiumStore, this.$store)
    this.features = compendium.NpcFeatures
  },
})































































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { NpcFeature } from '@/class'

export default Vue.extend({
  name: 'npc-template-selector',
  props: {
    npc: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    templates: [],
  }),
  computed: {
    availableTemplates(): NpcFeature[] {
      return this.templates.filter(x => !this.npc.Templates.some(y => y.ID === x.ID))
    },
  },
  created() {
    const compendium = getModule(CompendiumStore, this.$store)
    this.templates = compendium.NpcTemplates
  },
})

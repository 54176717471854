
















































































































































































































import Vue from 'vue'
export default Vue.extend({
  name: 'cc-clock',
  props: {
    size: { type: Number, required: false, default: 70 },
    clock: { type: Object, required: true },
    color: { type: String, required: false, default: 'primary' },
    print: { type: Boolean },
    noDelete: { type: Boolean },
  },
  data: () => ({
    editDialog: false,
  }),
  computed: {
    progress() {
      if (this.print) return 0
      return parseInt(this.clock.Progress)
    },
    total() {
      if (this.print) return 0
      return (parseInt(this.progress) / parseInt(this.clock.Segments)) * 100
    },
  },
})

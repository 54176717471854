
































































































































































































/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Vue, Component, Watch } from 'vue-property-decorator'
import PanelView from '../components/PanelView.vue'
import NpcCard from './NpcCard.vue'
import RosterGroup from './components/RosterGroup.vue'
import { getModule } from 'vuex-module-decorators'
import { NpcStore } from '@/store'
import { Npc, Statblock } from '@/class'
import { INpcData } from '@/interface'
import { importData } from '@/io/Data'
import { saveFile } from '@/io/Dialog'

@Component({
  name: 'npc-manager',
  components: { PanelView, NpcCard, RosterGroup },
})
export default class NpcManager extends Vue {
  search = ''
  selectedNpc: Npc = null
  grouping = null
  headers = [
    { text: 'Название', value: 'Name', align: 'left' },
    { text: 'Класс', value: 'Class.Name' },
    { text: 'Роль', value: 'Role' },
    { text: 'Градация', value: 'Tier' },
  ]
  importDialog = false
  statblockDialog = false
  npcImportFile: File = null
  importNpc: Npc = null
  statblockNpc = null

  @Watch('selectedNpc')
  onSelectedNpcChanged() {
    // this.$refs.view.resetScroll()
  }

  created() {
    const store = getModule(NpcStore, this.$store)
    this.npcs = store.Npcs
  }

  npcs = getModule(NpcStore, this.$store).Npcs

  setStatblock(npc: Npc) {
    this.statblockNpc = npc
    this.statblockDialog = true
  }

  statblock() {
    return Statblock.GenerateNPC(this.statblockNpc)
  }

  delete_npc(npc: Npc) {
    this.selectedNpc = null
    npc.SaveController.delete()
  }

  copyNpc(npc: Npc) {
    const store = getModule(NpcStore, this.$store)
    store.cloneNpc(npc)
  }

  exportNpc(npc: Npc) {
    saveFile(
      npc.Name.toUpperCase().replace(/\W/g, '') + '.json',
      JSON.stringify(Npc.Serialize(npc)),
      'Save NPC'
    )
  }

  @Watch('npcImportFile')
  async fileImport(file) {
    if (!file) return
    const npcData = await importData<INpcData>(file)
    this.importNpc = Npc.Deserialize(npcData)
    this.importNpc.RenewID()
  }

  confirmImport() {
    const store = getModule(NpcStore, this.$store)
    store.addNpc(this.importNpc)
    this.importNpc = null
    this.importDialog = false
    this.npcImportFile = null
  }
}

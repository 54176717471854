




































































































































































































































import Vue from 'vue'
import SyncItemMenu from './SyncItemMenu.vue'
import {
  ListCloudItems,
  ProcessItemsList,
  SyncItem,
  DeleteForever,
  GetLocalItem,
  Overwrite,
  FlagCloudDelete,
  FlagCloudRestore,
  AutoSyncAll,
  AutoSyncRemotes,
  RemoteSyncItem,
} from '@/cloud/item_sync'
import { ICloudSyncable } from '@/classes/components'
import { Pilot } from '@/classes/pilot/Pilot'
import sleep from '@/util/sleep'
import { SaveAllLocalUpdates } from '@/io/BulkData'

export default Vue.extend({
  name: 'sync-manager',
  components: { SyncItemMenu },
  data: () => ({
    loading: false,
    overwriteCloud: false,
    overwriteLocal: false,
    confirmOverwriteCloud: false,
    confirmOverwriteLocal: false,
    items: [],
    cloudItemTypes: ['Active Mission', 'Mission', 'Encounter', 'NPC', 'Pilot'],
  }),
  async mounted() {
    this.fetch()
  },
  computed: {
    selectedItems() {
      return this.items.filter(x => x.selected)
    },
  },
  methods: {
    displayTypes(type) {
      switch (type) {
        case 'Active Mission':
          return 'Активная Миссия'
        case 'Mission':
          return 'Миссия'
        case 'Encounter':
          return 'Бой'
        case 'NPC':
          return 'НИП'
        case 'Pilot':
        default:
          return 'Пилот'
      }
    },
    selectAll(type) {
      this.itemsByType(type).forEach(i => {
        this.$set(i, 'selected', true)
      })
    },
    deselectAll(type) {
      this.itemsByType(type).forEach(i => {
        this.$set(i, 'selected', false)
      })
    },
    itemsByType(type) {
      return this.items.filter(x => x.itemType === type)
    },
    callsign(item) {
      if (item.itemType !== 'Pilot') return ''
      const p = GetLocalItem(item) as Pilot
      if (p && p.Callsign) return `${p.Callsign} //`
    },
    isAtLatest(item) {
      if (!item.lastModifiedCloud || !item.lastModifiedLocal) return false
      const sDiff =
        Math.abs(
          new Date(item.lastModifiedCloud).valueOf() - new Date(item.lastModifiedLocal).valueOf()
        ) / 1000
      return sDiff < 15
    },
    async fetch() {
      this.loading = true
      ListCloudItems()
        .then(res => {
          this.items = ProcessItemsList(res)
        })
        .finally(() => (this.loading = false))
    },
    async syncSingle(item) {
      this.loading = true
      SyncItem(item)
        .then(() => this.fetch())
        .then(() => this.$notify('Синхронизация прошла успешно', 'success'))
        .catch(() =>
          this.$notify(
            'При синхронизации произошла ошибка. Возможно, вам не хватает одного или нескольких необходимых LCP.',
            'error'
          )
        )
    },
    async syncSelected() {
      this.loading = true
      Promise.all(this.selectedItems.map(item => SyncItem(item, true)))
        .then(() => SaveAllLocalUpdates())
        .then(() => this.fetch())
        .then(() =>
          this.$notify(`Синхронизировано ${this.selectedItems.length} объектов успешно`, 'success')
        )
        .catch(() =>
          this.$notify(
            'При синхронизации произошла ошибка. Возможно, вам не хватает одного или нескольких необходимых LCP.',
            'error'
          )
        )
    },
    async overwriteSingle(item, source, dest) {
      this.loading = true
      Overwrite(item, source, dest)
        .then(() => this.fetch())
        .then(() => this.$notify('Перезапись прошла успешно', 'success'))
        .catch(() => this.$notify('Произошла ошибка при перезаписи.', 'error'))
    },
    async overwriteSelected(source, dest) {
      this.loading = true
      Promise.all(this.selectedItems.map(item => Overwrite(item, source, dest, true)))
        .then(() => SaveAllLocalUpdates())
        .then(() => this.fetch())
        .then(() =>
          this.$notify(`Заменено ${this.selectedItems.length} объектов успешно`, 'success')
        )
        .catch(() => this.$notify('Произошла ошибка при перезаписи.', 'error'))
    },
    async undelete(item) {
      const local = GetLocalItem(item) as ICloudSyncable
      if (local) {
        local.SaveController.restore()
        this.fetch()
      } else FlagCloudRestore(item).then(() => this.fetch())
    },
    async flagDelete(item) {
      const local = GetLocalItem(item) as ICloudSyncable
      if (local) {
        local.SaveController.delete()
        this.fetch()
      } else FlagCloudDelete(item).then(() => this.fetch())
    },
    deleteForever(item) {
      DeleteForever(item)
        .then(() => this.fetch())
        .then(() => this.$notify('Удаление успешно', 'success'))
        .catch(() =>
          this.$notify('Произошла ошибка при попытке удалить эту запись.', 'error')
        )
    },
    syncAll(hideAlert?: boolean) {
      this.loading = true
      AutoSyncAll()
        .then(() => AutoSyncRemotes())
        .then(() => sleep(500))
        .then(() => this.fetch())
        .then(() => {
          if (!hideAlert) this.$notify(`Синхронизировано ${this.items.length} объектов успешно`, 'success')
        })
        .catch(() => {
          if (!hideAlert) this.$notify('При синхронизации произошла ошибка.', 'error')
        })
    },
    async remoteUpdate(item) {
      const local = GetLocalItem(item) as ICloudSyncable
      try {
        RemoteSyncItem(local)
        this.$notify('Пилот синхронизирован с удаленным', 'success')
        this.fetch()
      } catch (error) {
        console.error(error)
        this.$notify('Произошла ошибка при попытке загрузить удаленные данные.', 'error')
      }
    },
  },
})
































































































import Vue from 'vue'
import ReserveItem from './components/_ReserveItem.vue'
import CustomReservePanel from './components/_CustomReservePanel.vue'
import DowntimeProjectPanel from './components/_DowntimeProjectPanel.vue'
import OrganizationPanel from './components/_OrganizationPanel.vue'
import { Reserve, Organization, Pilot } from '@/class'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import _, { Dictionary } from 'lodash'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { ReserveItem, CustomReservePanel, DowntimeProjectPanel, OrganizationPanel },
})
export default class ReserveSelector extends Vue {
  @Prop(Pilot) readonly pilot?: Pilot
  tabModel: 0
  private compendium = getModule(CompendiumStore, this.$store)
  get reserves(): Dictionary<Reserve[]> {
    return _.groupBy(this.compendium.Reserves, 'Type')
  }
  add(reserve: Reserve): void {
    this.pilot.ReservesController.AddReserve(_.clone(reserve))
    this.$emit('close')
  }
  addOrg(org: Organization): void {
    this.pilot.ReservesController.AddOrganization(_.clone(org))
    this.$emit('close')
  }
}

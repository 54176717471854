



































import Vue from 'vue'
import Component from 'vue-class-component'
import DirectoryTable from './components/DirectoryTable.vue'
import { scan } from './api'

@Component({
  components: { DirectoryTable },
})
export default class PacksDirectory extends Vue {
  private massifPacks = []
  private communityPacks = []
  private loading = true

  async mounted(): Promise<void> {
    scan()
      .then((res: any) => {
        this.communityPacks = res.data.community.Items
        this.massifPacks = res.data.massif.Items
        this.loading = false
      })
      .catch(err => {
        console.error('Возникла проблема при загрузке пакетов контента сообщества.', err)
        this.loading = false
      })
  }
}



















































































































































































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

const icons = [
  'npc-template',
  'license',
  'npc-feature',
  'npc-class',
  'squad',
  'status-downandout',
  'condition-immobilized',
  'condition-slow',
  'manufacturer',
  'condition-stunned',
  'condition-shredded',
  'condition-lock-on',
  'condition-jammed',
  'status-shut-down',
  'status-prone',
  'status-invisible',
  'status-hidden',
  'status-exposed',
  'status-engaged',
  'status-danger-zone',
  'activation-full',
  'activation-quick',
  'tech-full',
  'full-tech',
  'tech-quick',
  'quick-tech',
  'invade',
  'edef',
  'downtime',
  'evasion',
  'npc-tier-custom',
  'npc-tier-3',
  'npc-tier-2',
  'npc-tier-1',
  'free-action',
  'reaction',
  'reserve-tactical',
  'reserve-mech',
  'reserve-resource',
  'reserve-bonus',
  'system-point',
  'generic-item',
  'save',
  'sensor',
  'weapon-profile',
  'protocol',
  'drone',
  'skill',
  'talent',
  'grenade',
  'deployable',
  'content-manager',
  'campaign',
  'encounter',
  'compendium',
  'mine',
  'ship',
  'vehicle',
  'accuracy',
  'activate',
  'range',
  'burst',
  'cone',
  'line',
  'corebonus',
  'burn',
  'energy',
  'explosive',
  'heat',
  'kinetic',
  'variable',
  'deactivate',
  'condition-impaired',
  'difficulty',
  'frame',
  'melee',
  'overcharge',
  'pilot',
  'thrown',
  'reactor',
  'repair',
  'role-artillery',
  'role-controller',
  'role-striker',
  'role-support',
  'role-tank',
  'role-defender',
  'size-1',
  'size-2',
  'size-3',
  'size-4',
  'size-half',
  'structure',
  'system',
  'mech-system',
  'threat',
  'weaponmod',
  'weapon-mod',
  'trait',
  'weapon',
  'mech-weapon',
  'blast',
  'rank-1',
  'rank-2',
  'rank-3',
  'nested-hexagons',
  'orbit',
  'orbital',
  'large-beam',
  'ammo',
  'burning',
  'balance',
  'reticule',
  'spikes',
  'eclipse',
  'sword-array',
  'marker',
  'barrage',
]
export default Vue.extend({
  name: 'ui-test',
  data: () => ({
    notificationText: 'test',
    notificationTypes: ['achievement', 'confirmation', 'error'],
    notificationType: 'confirmation',
    exampleTalents: [],
    chargeExample: null,
    deployExample: null,
    droneExample: null,
    multipleExample: null,
    aiExample: null,
    techExample: null,
    reactionExample: null,
    genericExample: null,
    profileExample: null,
    onAttackExample: null,
    onHitExample: null,
    onCritExample: null,
    asDroneExample: null,
  }),
  computed: {
    process() {
      return process
    },
    env() {
      return process.env
    },
  },
  created() {
    const s = getModule(CompendiumStore, this.$store)
    this.genericExample = s.MechSystems.find(x => x.ID === 'ms_eva_module')
    this.chargeExample = s.MechSystems.find(x => x.ID === 'ms_pattern_a_smoke_charges')
    this.deployExample = s.MechSystems.find(x => x.ID === 'ms_pattern_a_jericho_deployable_cover')
    this.droneExample = s.MechSystems.find(x => x.ID === 'ms_turret_drones')
    this.multipleExample = s.MechSystems.find(x => x.ID === 'ms_reinforced_cabling')
    this.aiExample = s.MechSystems.find(x => x.ID === 'ms_sekhmet_class_nhp')
    this.techExample = s.MechSystems.find(x => x.ID === 'ms_neurospike')
    this.reactionExample = s.MechSystems.find(x => x.ID === 'ms_singularity_motivator')
    this.profileExample = s.MechWeapons.find(x => x.ID === 'mw_siege_cannon')
    this.onAttackExample = s.MechWeapons.find(x => x.ID === 'mw_plasma_thrower')
    this.onHitExample = s.MechWeapons.find(x => x.ID === 'mw_annihilator')
    this.onCritExample = s.MechWeapons.find(x => x.ID === 'mw_chain_axe')
    this.asDroneExample = s.MechWeapons.find(x => x.ID === 'mw_ghast_nexus')
    this.exampleTalents = s.Talents.sort(() => 0.5 - Math.random()).slice(0, 4)
    console.log(this.exampleTalents)
  },
  methods: {
    allIcons() {
      return icons
    },
    allColors(theme) {
      const t = this.$vuetify.theme.themes[theme]
      const output = []
      Object.keys(t).forEach(e => {
        output.push({ name: e, color: t[e] })
      })
      return output
    },
    dialog1Confirm() {
      console.log('dialog 1 confirmed')
    },
    doNotify() {
      this.$notify(this.notificationText, this.notificationType, () =>
        console.log('yup, you clicked the notification!', this)
      )
    },
  },
})

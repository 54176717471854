









































































































































import Vue from 'vue'
import { getImagePath, ImageTag } from '@/io/ImageManagement'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

export default Vue.extend({
  name: 'template-item',
  props: {
    template: { type: Object, required: true },
    isSelected: { type: Boolean },
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    frameImage() {
      return getImagePath(ImageTag.Mech, this.template.image)
    },
  },
  methods: {
    item(type: string, id: string) {
      const compendium = getModule(CompendiumStore, this.$store)
      return compendium.referenceByID(type, id)
    },
  },
})

























































































import Vue from 'vue'
import IdentificationPage from './pages/IdentificationPage.vue'
import SkillsPage from './pages/SkillsPage.vue'
import TalentsPage from './pages/TalentsPage.vue'
import MechSkillsPage from './pages/MechSkillsPage.vue'
import ConfirmPage from './pages/ConfirmPage.vue'
import TemplatesPage from './pages/TemplatesPage.vue'
import { Pilot } from '@/class'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/store'

export default Vue.extend({
  name: 'new-pilot-wizard',
  components: {
    IdentificationPage,
    SkillsPage,
    TalentsPage,
    MechSkillsPage,
    ConfirmPage,
    TemplatesPage,
  },
  data: () => ({
    step: 1,
    pilot: {},
    done: false,
  }),
  computed: {
    quickstart() {
      return !!getModule(UserStore, this.$store).UserProfile.GetView('quickstart')
    },
  },
  watch: {
    step() {
      window.scrollTo(0, 0)
    },
  },
  created() {
    this.pilot = new Pilot()
  },
  methods: {
    onDone() {
      this.done = true
      this.$router.push('./pilot_management')
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.done) {
      next()
    } else {
      const confirmLeave = await this.$confirm(
        'Exit wizard?',
        'Are you sure you want to exit the wizard? Your pilot will be discarded.'
      )

      if (confirmLeave) next()
      else next(false)
    }
  },
})

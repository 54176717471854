



































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Range } from '@/class'
import { glossary } from 'lancer-data-rus'

@Component({ name: 'cc-range-element' })
export default class CCRangeElement extends Vue {
  @Prop({ type: Array, required: true })
  readonly range!: Range[]

  @Prop({ type: Boolean, required: false })
  readonly small?: boolean

  @Prop({ type: Boolean, required: false })
  readonly dark?: boolean

  @Prop({ type: Boolean, required: false })
  readonly added?: boolean

  Help(name: string): string {
    const g = glossary.find(x => x.name.toLowerCase() === name.toLowerCase())
    if (g)
      return `<div class="overline subtle--text mb-n2 mt-n2">${name}:</div><div>${g.description}</div>`
    return ''
  }
}



































































































import Vue from 'vue'
export default Vue.extend({
  name: 'sync-item-menu',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    local() {
      return this.item.latest === 'local'
    },
    noCloud() {
      return !this.item.lastModifiedCloud
    },
    noLocal() {
      return !this.item.lastModifiedLocal
    },
    hasRecords() {
      return this.item.lastModifiedLocal && this.item.lastModifiedCloud
    },
    synced() {
      return (
        !!this.item.lastModifiedCloud &&
        !!this.item.lastModifiedLocal &&
        this.item.lastModifiedCloud === this.item.lastModifiedLocal
      )
    },
  },
})




















































































































































































import Vue from 'vue'
import allThemes from '@/ui/style/themes'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/store'
import { exportAll, importAll, clearAllData } from '@/io/BulkData'
import { saveFile } from '@/io/Dialog'
import DeletedItems from './DeletedItems.vue'
import { SetTheme } from '@/classes/utility/ThemeManager'

export default Vue.extend({
  name: 'options-settings',
  components: { DeletedItems },
  data: () => ({
    themes: [],
    importDialog: false,
    fileValue: null,
    deleteDialog: false,
  }),
  computed: {
    user() {
      const store = getModule(UserStore, this.$store)
      return store.UserProfile
    },
    userViewExotics: {
      get: function () {
        return this.user.GetView('showExotics')
      },
      set: function (newval) {
        this.user.SetView('showExotics', newval)
      },
    },
    userAllowQuickstart: {
      get: function () {
        return this.user.GetView('quickstart')
      },
      set: function (newval) {
        this.user.SetView('quickstart', newval)
      },
    },
    userSaveStrategy: {
      get: function () {
        return this.user.GetView('savePerformant')
      },
      set: function (newval) {
        this.user.SetView('savePerformant', newval)
      },
    },
    theme: {
      get: function () {
        return this.user.Theme
      },
      set: function (newval) {
        this.user.Theme = newval
        SetTheme(this.theme, this.$vuetify)
      },
    },
    userID() {
      return this.user.id
    },
    userTheme() {
      return this.user.Theme
    },
  },
  created() {
    for (const k in allThemes) {
      const e = allThemes[k]
      this.themes.push({ name: e.name, value: e.id })
    }
  },
  methods: {
    reload() {
      location.reload()
    },
    showMessage() {
      const store = getModule(UserStore, this.$store)
      store.UserProfile.WelcomeHash = ''
      localStorage.removeItem('cc-welcome-hash')
      this.reload()
    },
    async bulkExport() {
      const result = await exportAll()
      await saveFile(
        `CC_${new Date().toISOString().slice(0, 10)}.compcon`,
        JSON.stringify(result),
        'Save COMP/CON Archive'
      )
    },
    async bulkImport(file) {
      await importAll(file)
        .then(() => this.$notify('Data import successful', 'confirmation'))
        .catch(err => this.$notify(`ERROR: Unable to import: ${err}`, 'error'))
      this.importDialog = false
    },
    async deleteAll() {
      await clearAllData(false)
      this.deleteDialog = false
    },
  },
})
















import Vue from 'vue'

export default Vue.extend({
  name: 'compendium-search-bar',
  data: () => ({
    searchText: '',
  }),
  methods: {
    search() {
      this.$router.push(`/compendium/search?search=${this.searchText}`)
    },
  },
})

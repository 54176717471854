






















































































































import Vue from "vue";
import UpdateChecker from './_components/UpdateChecker.vue'
import MobileBtn from "./_components/MobileBtn.vue";
import ContentPage from "../nav/pages/ExtraContent/index.vue";
import CreditsPage from "../nav/pages/Credits.vue";
import AboutPage from "../nav/pages/About.vue";
import HelpPage from "../nav/pages/Help.vue";
import OptionsPage from "../nav/pages/Options/index.vue";
import { UserStore } from "@/store";
import { getModule } from "vuex-module-decorators";
import SignIn from "./_components/login/index.vue";

export default Vue.extend({
  name: "landing-page-mobile",
  components: {
    UpdateChecker,
    MobileBtn,
    ContentPage,
    AboutPage,
    CreditsPage,
    HelpPage,
    OptionsPage,
    SignIn,
  },
  computed: {
    userstore() {
      return getModule(UserStore, this.$store);
    },
  },
});





















































































































import Vue from 'vue'

import CloneDialog from './CloneDialog.vue'
import StatblockDialog from './StatblockDialog.vue'
import Roll20Dialog from './Roll20Dialog.vue'
import ExportDialog from './ExportDialog.vue'
import ShareDialog from './ShareDialog.vue'
import PrintDialog from './PrintDialog.vue'
import DeleteDialog from './DeletePilotDialog.vue'

import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/store'
import { RemoteSyncItem } from '@/cloud/item_sync'

export default Vue.extend({
  name: 'edit-menu',
  components: {
    StatblockDialog,
    Roll20Dialog,
    ExportDialog,
    PrintDialog,
    DeleteDialog,
    CloneDialog,
    ShareDialog,
  },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
    light: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    isAuthed() {
      return getModule(UserStore, this.$store).IsLoggedIn
    },
  },
  methods: {
    delete_pilot() {
      this.pilot.SaveController.delete()
      if (this.$route.path !== '/pilot_management') this.$router.push('/pilot_management')
    },
    async remoteUpdate() {
      this.loading = true
      try {
        await RemoteSyncItem(this.pilot)
        this.$notify('Pilot synced to remote', 'success')
      } catch (error) {
        console.error(error)
        this.$notify('An error occurred while attempting to download remote data', 'error')
      }
      this.loading = false
    },
  },
})

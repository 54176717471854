







































import Vue from 'vue'
export default Vue.extend({
  name: 'action-card',
  props: {
    action: {
      type: Object,
      required: true,
    },
    downtime: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    exclusive() {
      if (this.action.IsPilotAction && !this.action.IsMechAction) return ' (Только пилот)'
      return ''
    },
  },
})

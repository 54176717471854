
















































































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

export default Vue.extend({
  name: 'content-pack-directory-table',
  props: {
    items: {
      type: Array,
      required: true,
    },
    noAuthor: { type: Boolean },
    loading: { type: Boolean },
  },
  computed: {
    tableHeaders() {
      if (this.noAuthor)
        return [
          { text: '', value: 'data-table-expand' },
          { text: 'Скачать', value: 'website', sortable: false, align: 'center' },
          { text: 'Название', value: 'name' },
          { text: 'Версия', value: 'version' },
          { text: 'Цена', value: 'cost' },
        ]
      return [
        { text: '', value: 'data-table-expand' },
        { text: 'Скачать', value: 'website', sortable: false, align: 'center' },
        { text: 'Название', value: 'name' },
        { text: 'Автор', value: 'author' },
        { text: 'Версия', value: 'version' },
        { text: 'Цена', value: 'cost' },
      ]
    },
    contentPacks() {
      return getModule(CompendiumStore, this.$store).ContentPacks
    },
  },
  methods: {
    packInstalled(item) {
      return this.contentPacks.some(x => x.Name === item.name || x.Name === item.title)
    },
    packOutdated(item) {
      const installedPack = this.contentPacks.find(
        x => x.Name === item.name || x.Name === item.title
      )
      if (!installedPack) return true
      return installedPack.Version !== item.version
    },
  },
})









































import Vue from 'vue'
export default Vue.extend({
  name: 'ammo-case-inset',
  props: {
    level: { type: Number, required: true, default: 0 },
    uses: { type: Number, required: false, default: 0 },
    readonly: { type: Boolean },
  },
  data: () => ({
    selected: null,
    allAmmo: [
      {
        name: 'Стандартные',
        cost: 0,
        damage: '',
        effect: '',
      },
      {
        name: 'Громадина',
        cost: 1,
        damage: 'explosive',
        effect: 'Атака приобретает эффект Толчок 1 и наносит взрывной урон.',
      },
      {
        name: 'Шок',
        cost: 1,
        damage: 'energy',
        effect:
          'Атака наносит урон энергетический урон. Выберите одну цель, на которую направлена ваша атака; соседние персонажи получают 1 ББ энергетический урон, независимо от того, попали вы или промахнулись.',
      },
      {
        name: 'Дозатор Гранат',
        cost: 1,
        damage: 'kinetic',
        effect: 'Атака становится Дуговой и наносит кинетический урон.',
      },
      {
        name: 'Хелфаер',
        cost: 2,
        damage: 'energy',
        effect: 'Атака наносит энергетический урон и наносит бонусный урон как Горение.',
      },
      {
        name: 'Джагер',
        cost: 2,
        damage: 'explosive',
        effect: 'Атака получает значение Толчок 2, наносит взрывной урон, и один персонаж, пораженный атакой — на ваш выбор — должен успешно пройти спасбросок Корпуса или он будет Сбит с ног.',
      },
      {
        name: 'Подкалиберный',
        cost: 2,
        damage: 'kinetic',
        effect: 'Атака получает ББ и наносит кинетический урон.',
      },
    ],
  }),
  computed: {
    ammoItems() {
      if (this.level < 2) return this.allAmmo.slice(0, 4)
      return this.allAmmo
    },
  },
  created() {
    this.selected = this.allAmmo[0]
  },
  methods: {
    setSelection(ammo) {
      this.$emit('set-cost', ammo.cost)
      this.$emit('set-damage', ammo.damage)
    },
  },
})












































import Vue from 'vue'
export default Vue.extend({
  name: 'action-confirm-log',
  props: {
    used: { type: Boolean },
    action: {
      type: Object,
      required: true,
    },
    mech: {
      type: Object,
      required: true,
    },
    hideLog: { type: Boolean },
    completeCondition: {
      type: Boolean,
      required: false,
      default: true,
    },
    logOverride: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    timer: 0,
  }),
  computed: {
    finalLog() {
      const out =
        this.logOverride && this.logOverride.length ? this.logOverride : this.action.Confirm
      if (this.action.HeatCost) out.push('ВНИМАНИЕ: УРОВЕНЬ НАГРЕВА РЕАКТОРА УВЕЛИЧИВАЕТСЯ')
      return out
    },
    finished() {
      return this.completeCondition && this.used
    },
  },
  watch: {
    used: {
      immediate: true,
      deep: true,
      handler: function(newval) {
        if (newval) this.runTimeout()
        else this.init()
      },
    },
  },
  methods: {
    runTimeout() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this
      const timer = setInterval(function() {
        self.timer++

        if (self.timer > self.action.Confirm.length * 10) {
          clearInterval(timer)
        }
      }, 80)
    },
    init() {
      this.timer = 0
    },
    undo() {
      this.$emit('undo')
      this.init()
    },
  },
})

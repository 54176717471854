



























































































import Vue from 'vue'
import { Reserve } from '@/class'
export default Vue.extend({
  name: 'get-connected',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    skillRoll: '',
    custom_name: '',
    details: '',
  }),
  methods: {
    addReserve() {
      const nr = new Reserve({
        id: 'reserve_boughttime',
        type: 'Ресурс',
        name: 'Контакт',
        label: '',
        description: '',
        resource_note: this.details,
        resource_name: this.custom_name,
        resource_cost: '',
        used: false,
        consumable: true,
      })
      if (this.skillRoll < 10)
        nr.ResourceCost =
          'Вы должны сделать одолжение или выполнить обещание прямо сейчас'
      else if (this.skillRoll < 20)
        nr.ResourceCost = 'Вы должны сделать одолжение или выполнить обещание после этого'
      this.pilot.ReservesController.AddReserve(nr)
      this.close()
    },
    close() {
      this.skillRoll = ''
      this.custom_name = ''
      this.details = ''
      this.$emit('close')
    },
  },
})




























































































































import Vue from 'vue'
import { Pilot } from '@/class'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore, PilotManagementStore } from '@/store'
import { getRecord } from '@/io/apis/share'
import { GetSingleRemote } from '@/cloud/item_sync'
import { Auth } from 'aws-amplify'

export default Vue.extend({
  name: 'share-import',
  data: () => ({
    cid: '',
    shareCode: '',
    loading: false,
    pilotData: null,
    oldBrewsWarning: false,
    missingContent: '',
    stagedData: null,
    alreadyPresentItem: null,
    isSameUser: false,
    searchResults: null,
  }),
  async mounted() {
    this.cid = await Auth.currentUserCredentials()
      .then(res => res.identityId)
      .catch(() => '')
  },
  watch: {
    searchResults(newval) {
      if (newval && newval.Item) {
        if (newval.Item.iid === this.cid) {
          this.isSameUser = true
        }
        this.downloadPilotData()
      }
    },
  },
  methods: {
    reset() {
      this.shareCode = ''
      this.loading = false
      this.pilotData = null
      this.oldBrewsWarning = false
      this.missingContent = ''
      this.stagedData = null
      this.alreadyPresentItem = null
      this.searchResults = null
      this.isSameUser = false
    },
    async search() {
      this.loading = true
      this.shareCode = this.shareCode.toUpperCase()
      getRecord(this.shareCode)
        .then(res => {
          this.searchResults = res.data
          if (!res.data.Item) this.loading = false
        })
        .catch(err => {
          console.error(err)
          this.$notify('Произошла ошибка при поиске кода.', err)
          this.loading = false
        })
    },
    async downloadPilotData() {
      const record = this.searchResults.Item
      GetSingleRemote(record.key, record.iid)
        .then(res => {
          this.pilotData = res
        })
        .then(() => this.stageImport())
        .catch(err => {
          console.error(err)
          this.$notify('Произошла ошибка при загрузке удаленных данных.', err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async stageImport() {
      if (!this.pilotData.brews) this.pilotData.brews = []
      // catch old style brews
      if (this.pilotData.brews.length && !this.pilotData.brews[0].LcpId) {
        this.oldBrewsWarning = true

        const installedPacks = getModule(CompendiumStore, this.$store)
          .ContentPacks.filter(x => x.Active)
          .map(x => `${x.Name} @ ${x.Version}`)
        const missingPacks = this.$_.pullAll(this.pilotData.brews, installedPacks)
        if (missingPacks.length) this.missingContent = missingPacks.join('<br />')
      } else {
        const installedPacks = getModule(CompendiumStore, this.$store)
          .ContentPacks.filter(x => x.Active)
          .map(x => x.ID)
        let missing = []
        this.pilotData.brews.forEach(b => {
          if (!installedPacks.includes(b.LcpId)) {
            missing.push(`${b.LcpName} @ ${b.LcpVersion}`)
          }
        })
        if (missing.length) this.missingContent = missing.join('<br />')
      }
      const ap = getModule(PilotManagementStore).AllPilots.find(x => x.ID === this.pilotData.id)
      if (ap) {
        this.alreadyPresentItem = ap
      }
      this.stagedData = this.pilotData
    },
    importAsCopy() {
      console.log('importing as copy')
      try {
        this.pilotData.name += '※'
        this.pilotData.callsign += '※'
        const importPilot = Pilot.Deserialize(this.stagedData)
        importPilot.GroupController.reset()
        importPilot.CloudController.reset()
        importPilot.RenewID()
        this.$store.dispatch('addPilot', importPilot)
        this.reset()
        this.$emit('done')
        this.$notify('Пилот успешно импортирован', 'success')
      } catch (error) {
        this.$notify(
          'Во время попытки импорта произошла ошибка. Пожалуйста, проверьте журнал консоли.',
          'error'
        )
      }
    },
    importAsRemote() {
      console.log('importing as remote')
      try {
        const importPilot = Pilot.Deserialize(this.stagedData)
        importPilot.GroupController.reset()
        const record = this.searchResults.Item
        importPilot.CloudController.SetRemoteResource(record.iid, record.key)
        this.$store.dispatch('addPilot', importPilot)
        this.reset()
        this.$emit('done')
        this.$notify('Пилот успешно импортирован', 'success')
      } catch (error) {
        this.$notify(
          'Во время попытки импорта произошла ошибка. Пожалуйста, проверьте журнал консоли.',
          'error'
        )
      }
    },
    cancelImport() {
      this.reset()
    },
    sanitizeShareCode() {
      if (!this.shareCode) this.shareCode = ''
      this.shareCode = this.shareCode.replaceAll(' ', '')
    },
    deleteAP() {
      const ps = getModule(PilotManagementStore, this.$store)
      ps.deletePilotPermanent(ps.AllPilots.find(x => x.ID === this.alreadyPresentItem.ID))
      this.alreadyPresentItem = null
    },
  },
})

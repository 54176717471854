




















































import _ from 'lodash'
import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'
import ItemSelectorRow from '../../components/_ItemSelectorRow.vue'
import InvadeDialog from './_InvadeDialog.vue'

import Vue from 'vue'
import { ActivationType } from '@/class'

export default Vue.extend({
  name: 'quick-tech-dialog',
  components: { ActionDetailExpander, InvadeDialog, ItemSelectorRow },
  props: {
    used: { type: Boolean },
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selected: null,
    index: -1,
    usedArr: [],
  }),
  computed: {
    invadeAction() {
      return this.state.TechActions.find(x => x.ID === 'act_invade')
    },
    state() {
      return this.mech.Pilot.State
    },
    actions() {
      const qtArr = this.state.TechActions.filter(
        x => x.Activation === ActivationType.QuickTech && x.ID !== 'act_invade'
      )
      return _.groupBy(qtArr, 'Origin')
    },
  },
  methods: {
    removeIdx() {
      this.usedArr.splice(this.usedArr.indexOf(this.index), 1)
    },
    removeInvade() {
      this.usedArr.splice(this.usedArr.indexOf('invade'), 1)
    },
    commitAction(a) {
      this.state.CommitAction(this.action, a.Activation)
    },
    activate(action, index) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this
      this.selected = action
      this.index = index
      Vue.nextTick()
        .then(() => {
          self.selected = action
          this.index = index
        })
        .then(() =>
          Vue.nextTick().then(() => {
            if (self.isInvade) self.$refs.inv_dialog.show()
            else self.$refs.i_dialog.show()
          })
        )
    },
    init() {
      this.selected = null
      this.index = -1
      this.usedArr = this.usedArr.splice(0, this.usedArr.length)
    },
  },
})

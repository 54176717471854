



























































import Vue from 'vue'

export default Vue.extend({
  name: 'cc-bond-info',
  props: { bond: { type: Object, required: true } },
})
























































import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'
import ItemSelectorRow from '../../components/_ItemSelectorRow.vue'
import WSkirmishDialog from './_SelSkirmishDialog.vue'
import SelFightDialog from './_SelFightDialog.vue'

import Vue from 'vue'

export default Vue.extend({
  name: 'overwatch-dialog',
  components: {
    ActionDetailExpander,
    ItemSelectorRow,
    WSkirmishDialog,
    SelFightDialog,
  },
  props: {
    used: { type: Boolean },
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selected: null,
    selectedMount: null,
  }),
  computed: {
    state() {
      return this.mech.Pilot.State
    },
  },
  methods: {
    completeOverwatch(free) {
      this.state.CommitAction(this.action, free)
    },
    pilotOverwatch(item) {
      this.selected = item
      this.$refs.f_dialog.show()
    },
    overwatch(item, mount) {
      this.selected = item
      this.selectedMount = mount
      this.$refs.s_dialog.show()
    },
  },
})

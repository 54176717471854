





































import Vue from 'vue'
import WeaponAttack from '../../components/_WeaponAttack.vue'

export default Vue.extend({
  name: 'superheavy-barrage-dialog',
  components: { WeaponAttack },
  props: {
    mech: {
      type: Object,
      required: true,
    },
    cached: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    state() {
      return this.mech.Pilot.State
    },
    item() {
      if (!this.state.SHBarrageSelection) return this.cached
      return this.state.SHBarrageSelection
    },
    mount() {
      return this.state.SHBarrageMount
    },
  },
  methods: {
    reset() {
      this.$refs.main.reset()
    },
    confirm(): void {
      this.dialog = false
      this.$emit('confirm')
      this.$refs.main.init()
    },
    show(): void {
      this.dialog = true
    },
    hide(): void {
      this.dialog = false
      this.$emit('close')
      this.$refs.main.init()
    },
  },
})

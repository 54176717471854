































import Vue from 'vue'
import NpcPanel from './NpcPanel.vue'

export default Vue.extend({
  name: 'npc-chip',
  components: { NpcPanel },
  props: {
    npc: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
    },
    reinforce: {
      type: Boolean,
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
  },
})

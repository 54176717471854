







































































































































































































































































































































































































































































import activePilot from '@/features/pilot_management/mixins/activePilot'
import vueMixins from '@/util/vueMixins'
import ActiveModeLoadout from '../layout/ActiveModeLoadout.vue'

function normalize(current, max): number {
  if (!max) return 1
  return current / max
}

export default vueMixins(activePilot).extend({
  name: 'active-rest',
  components: { ActiveModeLoadout },
  data: () => ({
    rest: false,
    diagNumber: 0,
    selfRepair: 0,
    allyRepair: 0,
    showRepair: true,
    showReserves: true,
  }),
  computed: {
    mech() {
      return this.pilot.ActiveMech
    },
    loadout() {
      return this.mech.MechLoadoutController.ActiveLoadout
    },
    destroyedWeapons() {
      return this.loadout.Weapons.filter(x => x.Destroyed)
    },
    destroyedSystems() {
      return this.loadout.Systems.filter(x => x.Destroyed)
    },
    cheapStruct() {
      return this.mech.FeatureController.Bonuses.some(x => x.ID === 'cheap_struct')
    },
    cheapStress() {
      return this.mech.FeatureController.Bonuses.some(x => x.ID === 'cheap_stress')
    },
    issues() {
      return (
        (this.mech.CurrentHP === this.mech.MaxHP ? 0 : 1) +
        (this.mech.CurrentStructure === this.mech.MaxStructure ? 0 : 1) +
        (this.mech.CurrentStress === this.mech.MaxStress ? 0 : 1) +
        this.destroyedWeapons.length +
        this.destroyedSystems.length
      )
    },
    progress(): number {
      return (
        ((normalize(this.mech.CurrentHP, this.mech.MaxHP) +
          normalize(
            this.loadout.Weapons.length - this.destroyedWeapons.length,
            this.loadout.Weapons.length
          ) *
            3 +
          normalize(
            this.loadout.Systems.length - this.destroyedSystems.length,
            this.loadout.Systems.length
          ) *
            3 +
          normalize(this.mech.CurrentStructure, this.mech.MaxStructure) * 10 +
          normalize(this.mech.CurrentStress, this.mech.MaxStress) * 8) /
          24) *
        100
      )
    },
  },
  methods: {
    expandAll(len: number, key: string, expand: boolean) {
      for (let i = 0; i < len; i++) {
        const k = key + i
        this.$refs[k][0].collapsed = expand
      }
    },
  },
})

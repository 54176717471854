









































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

export default Vue.extend({
  name: 'statuses',
  data: () => ({
    statuses: [],
  }),
  created() {
    const compendium = getModule(CompendiumStore, this.$store)
    this.statuses = compendium.Statuses
  },
})






































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ name: 'status-select' })
export default class CCStatusSelect extends Vue {
  @Prop({ type: Array, required: true })
  readonly items!: Status[]

  @Prop({ required: true })
  readonly model!: Status[]

  @Prop({ type: String, required: false, default: '' })
  readonly color: string

  @Prop({ type: String, required: false, default: '' })
  readonly label: string

  @Prop({ type: Boolean })
  readonly dark?: boolean

  get arr(): any {
    return this.model
  }
  set arr(val) {
    if (!Array.isArray(val)) val = [val]
    this.$emit('set', val)
  }
}




























import Vue from 'vue'
import deployableInfoBase from './_deployableInfoBase.vue'
import { ActivationType } from '@/class'

export default Vue.extend({
  name: 'deployable-info-panel',
  components: { deployableInfoBase },
  props: {
    deployable: {
      type: Object,
      required: true,
    },
  },
  computed: {
    activation() {
      return this.deployable.activation ? <ActivationType>this.deployable.activation : ActivationType.Quick
    },
    color() {
      return `action--${Object.keys(ActivationType).find(key => ActivationType[key] === (this.deployable.activation ? <ActivationType>this.deployable.activation : ActivationType.Quick)).toLowerCase()}`
    }
  },
})
